import { useAlert } from "../../context/AlertContext";
import { copyToClipboard } from "../../util/clipboard";

export default function FullAccessSerial({ openSecurer, serial, setActiveSerial }) {
    const { addAlert } = useAlert();

    const dateUploaded = serial.dateUploaded;

    const openSecurerModal = () => {
        openSecurer(true);
        setActiveSerial(serial.serialId);
    };

    return (
        <div className="fasm-alt">
            {!serial.claimedEmail && dateUploaded > "2024-07-02T20:52:01.278334" ? (
                <div className="fasm-alt-cont">
                    <div className="fasm-info-cont">
                        <div>
                            <div className="fa-info-title">Username</div>
                            <div className="fa-info-details">
                                <div className="content">
                                    <span 
                                        className="text-to-copy" 
                                        onClick={(e) => copyToClipboard(e.target.innerText, addAlert)}
                                    >
                                        {serial.serialId.split(" - ")[1] 
                                            ? serial.serialId.split(" - ")[1].split(" ")[0] 
                                            : "Unknown"}
                                    </span>
                                    <span id="clipboardIcon" className="clipboard-icon fade-in">
                                        <i className="fas fa-copy" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="fa-info-title">Instructions</div>
                            <div className="fa-info-details">
                                <div className="content">
                                    <a 
                                        className="text-to-copy" 
                                        href="https://youtu.be/T9lAHSF2Hx8" 
                                        target="_blank" 
                                        rel="noreferrer"
                                    >
                                        Open Instructions
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {serial.serialId.split(" - ")[1] ? (
                        <div className="fasm-alt-actions">
                            <div className="fasm-alt-claim-now" onClick={openSecurerModal}>
                                Claim On Your Email
                            </div>
                        </div>
                    ) : (
                        serial.serialId.split(":").length === 3 ?(
                            <div className="fasm-alt-actions">
                                <div className="fasm-alt-claim-now" onClick={openSecurerModal}>
                                    Claim On Your Email
                                </div>
                            </div>
                        ) : (
                            <div className="fasm-alt-actions">
                                <div className="fasm-alt-claimed">
                                    Create ticket on Discord
                                </div>
                            </div>
                        )
                    )}
                </div>
            ) : (
                <div>
                    {dateUploaded > "2024-07-02T20:52:01.278334" ? (
                        <div className="fasm-alt-cont">
                            <div className="fasm-info-cont">
                                <div>
                                    <div className="fa-info-title">Claimed Email</div>
                                    <div className="fa-info-details">
                                        <div className="content">
                                            <span 
                                                className="text-to-copy" 
                                                onClick={(e) => copyToClipboard(e.target.innerText, addAlert)}
                                            >
                                                {serial.claimedEmail}
                                            </span>
                                            <span id="clipboardIcon" className="clipboard-icon fade-in">
                                                <i className="fas fa-copy" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fasm-alt-actions">
                                <div className="fasm-alt-claimed">Claimed</div>
                            </div>
                        </div>
                    ) : (
                        <div className="fasm-alt-cont">
                            <div className="fasm-info-cont">
                                <div>
                                    <div className="fa-info-title">Account Details</div>
                                    <div className="fa-info-details">
                                        <div className="content">
                                            <span 
                                                className="text-to-copy" 
                                                onClick={(e) => copyToClipboard(e.target.innerText, addAlert)}
                                            >
                                                {serial.serialId}
                                            </span>
                                            <span id="clipboardIcon" className="clipboard-icon fade-in">
                                                <i className="fas fa-copy" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}