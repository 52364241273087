import React from "react";

export default function AndroidLogo({ width, height, color }) {
    return (
        <svg
          version="1.1"
          id="fi_5968993"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width={width}
          height={height}
          viewBox="0 0 512 512"
          xmlSpace="preserve"
        >
          <path
            fill={color}
            d="M373.825,327.289c-11.776,0.002-21.324-9.542-21.326-21.318c-0.002-11.776,9.542-21.323,21.318-21.326  c11.776-0.002,21.323,9.542,21.326,21.318C395.13,317.732,385.595,327.271,373.825,327.289 M138.175,327.289  c-11.776,0.002-21.323-9.542-21.326-21.318c-0.002-11.776,9.542-21.323,21.318-21.326c11.776-0.002,21.323,9.542,21.326,21.318  C159.481,317.733,149.945,327.273,138.175,327.289 M381.471,198.865l42.607-73.796c2.451-4.239,1.001-9.662-3.238-12.113  c-4.239-2.451-9.663-1.001-12.113,3.238l0,0l-43.146,74.728c-32.993-15.058-70.047-23.443-109.585-23.443  s-76.588,8.397-109.581,23.443l-43.142-74.728c-2.447-4.24-7.869-5.694-12.109-3.246c-4.24,2.447-5.694,7.869-3.246,12.109l0,0  l42.611,73.808C57.364,238.66,7.32,312.729,0,400.241h512C504.672,312.729,454.632,238.66,381.471,198.865"
          />
        </svg>
      );
};