export default function Alerts({ alerts }) {
    return (
        <div className="alerts-container">
            {alerts.map(({ id, content, type }) => (
                <div key={id} className={`notif-popup ${type}-popup`}>
                    {content}
                </div>
            ))}
        </div>
    );
}