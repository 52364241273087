import { Link }             from "react-router-dom";

export default function FeaturedProduct({ product })
{
    return (
        <Link className="featured-product-holder" to={`/products/${product.productNameId}`}>
            <img
                className="featured-product-img"
                src={product.imageUrl}
                alt={product.title}
            />
            <div className="featured-product-price-cont">
                <div className="featured-product-price">${product.price?.toFixed(2)}</div>
                <div className="featured-product-discount">${(product.price * 1.3).toFixed(2)}</div>
            </div>
        </Link>
    );
}