import api from "./apiConfig";
import { RouteConstants } from "../constants/AppConstants";

export const getGoogleOAuth = async (accessToken) =>
{
    try{
        const response = await api.get(`${RouteConstants.OAUTH_URL}/google-success`, {
            headers: { Authorization: `${accessToken}` }
        });
        console.log(response)
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}