
import { useLocation } from "react-router-dom";
import { AppConstants }         from "../../constants/AppConstants";
import discord                  from "../../images/discord.webp";

export default function HoverFooter()
{
    const location = useLocation();
    
    const mainRoute = location.pathname.split("/")[1] || "";
    const noHoverFooterRoute = [
        "products"
    ];

    return (
        <div className="hover-footer">
            {!noHoverFooterRoute.includes(mainRoute) && <div className="hf-items-cont">
                <a href={AppConstants.DISCORD_REGULAR_INVITE} target="_blank" rel="noreferrer" className="hf-item">
                    <img className="hf-img" src={discord} alt="Discord Support Icon"/>
                </a>
            </div>}
        </div>
    );
}
