import api                                from "./apiConfig";

import { AppConstants, RouteConstants }   from "../constants/AppConstants";

export const getUser = async () =>
{
  try{
    const response = await api.get(RouteConstants.USERS_URL)
    return response.data;
  }catch(error){
    throw error;
  }
}

export const loginUser = async (userData) =>
{
    try{
      const response = await api.get(`${RouteConstants.LOGIN_API_URL}`, userData);
      return response;
    }catch (err) {
      console.log(err)
      return err.response;
    }
}

export const sendForgotPasswordLink = async (email) => {
  try
  {
    const response = await api.post(`${AppConstants.BASE_URL}/forgot-password?email=${email}`)
    return response;
  }catch(err){
    console.log(err);
    return err.response;
  }
}

export const resetPassword = async (token, newPassword) => {
  try
  {
    const response = await api.post(`${AppConstants.BASE_URL}/reset-password?token=${token}&newPassword=${newPassword}`);
    return response;
  }catch(err){
    console.log(err);
    return err.response;
  }
}

/**
 * Gets user information based on the UTOK token stored
 * 
 * @returns The response containing the user info
 */
export const getUserInfo = async () => {
  try{
    const response = await api.get(`${RouteConstants.USERS_URL}/info`);
    return response;
  }catch(err){
    console.log(err);
    return err.response;
  }
}