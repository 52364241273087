
export default function DifficultyDecidingBetweenProducts()
{
    return (
        <>
            <div className="support-questions-header">I Am Having Difficulties Deciding Which Product I Should Purchase.</div>
            <article className="support-questions-solution">
                <section>
                    <h3 className="support-answers-section-header">Full Access vs Temporary Minecraft</h3>
                    <p>If you are botting/afk farming/cheating, we high recommend buying our temporary products. The best seller is the xbox gamepass accounts as they are full access and will indefinitely last between 7 - 14 days.</p>
                    <p>If you want to play Minecraft with friends and believe you will play from time to time, buy a full access account. Other reasons to buy a full access account would be to start a new main account for Minecraft. Temporary accounts will expire or be unaccessible after their warranty time period.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Banned vs Unbanned vs Fresh</h3>
                    <p>Banned simply means that the product may be unable to access the Minecraft server called "Hypixel". If you want to play on that server, purchase an "Unbanned" account. If you never plan on playing on Hypixel, then buying a "Banned" account will save you money.</p>
                    <p>Fresh means that the stats of the account are "Fresh" on Hypixel. This means that the player has not made any progress on the server Hypixel which are mainly used for players that want to grind stats on the server. Otherwise, there is no difference between Fresh and Unbanned accounts.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}