import api from "./apiConfig";
import { RouteConstants } from "../constants/AppConstants";

export const getProductByNameId = async (name) => {
    try {
        const response = await api.get(RouteConstants.PRODUCTS_URL + "/" + name)
        return response;
    }catch(err){
        console.log(err)
        return err.response;
    }
}

export const getProducts = async (type) =>
{
    try {
        const response = await api.get(`${RouteConstants.PRODUCTS_URL}/type/${type}`);
        return response.data
    }catch(err){
        console.log(err)
        return err.response;
    }
}

export const createProduct = async (product) => {
    try {
        const response = await api.post(RouteConstants.PRODUCTS_URL, product)
        return response.data
    }catch(err){
        console.log(err)
        return err.response
    }
}

export const updateProduct = async (productId, product) => {
    try {
        const response = await api.put(RouteConstants.PRODUCTS_URL + "/" + productId, product)
        return response
    }catch(err){
        console.log(err)
        return err.response
    }
}