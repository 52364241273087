import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProducts } from "../../services/product";
import AccountPreview from "./AccountPreview";

export default function SpecialAccounts() {
    const [accounts, setAccounts] = useState([]);
    const [selectedCape, setSelectedCape] = useState(null);
    const [showBedwars, setShowBedwars] = useState(false);

    useEffect(() => {
        const fetchAccounts = async () => {
            if (accounts.length === 0)
            {
                try {
                    const retrievedAccounts = await getProducts("accounts");
                    setAccounts(retrievedAccounts);
                } catch (error) {
                    console.error("Error fetching accounts:", error);
                }
            }
        };

        fetchAccounts();
    }, []);

    // List of cape options for filtering
    const capesList = [
        "Cherry Blossom", "Vanilla", "Migrator", "MCC 15th Year",
        "Follower's", "Purple Heart", "Mojang Office", "15th Anniversary"
    ];

    // Filtering logic
    const filteredAccounts = accounts.filter(product => {
        const { account } = product;

        // Check for selected cape filter
        if (selectedCape && !account.capes.includes(selectedCape)) {
            return false;
        }

        // Check for Bedwars filter
        if (showBedwars && account.gamemode !== "bedwars") {
            return false;
        }

        return true;
    });

    return (
        <div className="statfinder-cont">
            <title>Full Access Hypixel Minecraft Bedwars Accounts For Sale | Buy Now!</title>
            <meta property="og:title" content="Full Access Hypixel Minecraft Bedwars Accounts For Sale | Buy Now!" />
            <meta property="og:site_name" content="Full Access Hypixel Minecraft Bedwars Accounts For Sale | Buy Now!" />
            <meta name="description" content="Buy Minecraft Java + Bedrock Unbanned Hypixel Bedwars Accounts. Ranging from 100 to 2000 star accounts, we have it all!" />

            <div className="statfinder-modal">
                <div className="stats-header-cont">
                    <Link className="checkout-header-go-back-cont" to={"/"}>
                        <svg 
                            className="checkout-header-go-back" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="50 0 450 450" 
                            width="25px" 
                            height="25px" 
                            fill="white">
                            <polygon points="352,128.4 319.7,96 160,256 319.7,416 352,383.6 224.7,256" />
                        </svg>
                    </Link>
                    <h1 className="stats-header-cont-header">Purchase Hypixel, Multi-Cape, Bedwars, and other custom accounts on sale.</h1>
                </div>

                {/* Filter Section */}
                <div className="special-accounts-filter-cont">
                    {/* Bedwars Filter */}
                    <div 
                        className={`special-accounts-filter-item ${showBedwars ? "active" : ""}`}
                        onClick={() => setShowBedwars(prev => !prev)}
                    >
                        Bedwars
                    </div>

                    {/* Capes Filter */}
                    {capesList.map(cape => (
                        <div
                            key={cape}
                            className={`special-accounts-filter-item ${selectedCape === cape ? "active" : ""}`}
                            onClick={() => setSelectedCape(selectedCape === cape ? null : cape)}
                        >
                            {cape}
                        </div>
                    ))}
                </div>

                {/* Accounts List */}
                <div className="sf-main-cont">
                    {filteredAccounts.length === 0 ? (
                        <div className="sf-no-matches">
                            No Results Found
                            <p>Please try another search</p>
                        </div>
                    ) : (
                        <div className="sf-accounts-preview-cont">
                            {filteredAccounts.map((product, index) => (
                                <AccountPreview key={index} product={product} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}