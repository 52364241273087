import { useState }             from "react";

import logo                     from '../../images/logo.webp';
import verifyEmail              from '../../images/verifyEmail.gif';
import checkCode                from '../../images/checkCode.gif';
import validEmail               from '../../images/validEmail.webp';

import { securer }              from "../../services/securer";
import { ShopInfo } from "../../constants/AppConstants";

export default function Securer({isOpen, setOpen, activeSerial})
{
    const [ newEmail, setNewEmail ]             = useState("");
    const [ code, setCode ]                     = useState("");
    const [ verifyLink, setVerifyLink ]         = useState("");

    const [ loginEmail, setLoginEmail ]         = useState("");
    const [ recoveryCode, setRecoveryCode ]     = useState("");

    const [ error, setError ]                   = useState("");
    const [ status, setStatus ]                 = useState("");
    const [ step, setStep ]                     = useState("step-1");

    const infoForSecuring = {}

    const handleEmailChange = (e) => setNewEmail(e.target.value);
    const handleCodeChange = (e) => setCode(e.target.value);
    const handleVerifyLinkChange = (e) => setVerifyLink(decodeURIComponent(e.target.value));

    const closeModal = () =>
    {
        setOpen(false);
        window.location.reload()
    }

    const onSubmitStartProcess = async (e) => 
    {
        // Prevent the default form submssion
        e.preventDefault();
        if(newEmail.length < 5 || !newEmail.includes("@"))
            return setError("invalid-email");
        if(newEmail.includes("@outlook") || newEmail.includes("@hotmail") || newEmail.includes("@live") || newEmail.includes("@msn"))
            return setError("email-taken");
        infoForSecuring.account = activeSerial;
        infoForSecuring.newEmail = newEmail;

        setStatus("step-1-attempt");
        setError("");

        const response = await securer(infoForSecuring, "startprocess");

        if(!response)
        {
            setError("unknown-err");
            setStatus("pending");
        }

        const secureStatus = response.data;

        switch(secureStatus)
        {
            case "EMAIL_TAKEN":
                setError("email-taken");
                return setStatus("pending");
            case "SUCCESS":
                setNewEmail(newEmail);

                setStep("step-2");
                setError("");
                return setStatus("pending");
            case "LOCKED":
                setError("account-locked");
                return setStatus("pending");
            case "INTERNAL_SERVER_ERROR":
            default:
                setLoginEmail(activeSerial.split(":")[0]);
                setRecoveryCode(activeSerial.split(":")[2].split(" ")[0]);

                setStep("failed");
                return setStatus("pending");
        }
    }

    const onSubmitCheckCode = async (e) => 
    {
        e.preventDefault();

        const regex = /^\d+$/;
        if(!regex.test(code) || code.length !== 6)
            setError("incorrect-code");
        else{
            setStatus("step-2-attempt");
            setError("");

            infoForSecuring.account = activeSerial;
            infoForSecuring.code = code;

            const response = await securer(infoForSecuring, "checkcode")

            if(!response)
            {
                setError("unknown-err");
                setStatus("pending");
            }

            const checkCodeStatus = response.data;

            switch(checkCodeStatus)
            {
                case "INVALID_CODE":
                    return setError("incorrect-code");
                case "SUCCESS":
                    setStep("step-3");
                    setStatus("pending")
                    return setError("");
                case "INTERNAL_SERVER_ERROR":
                default:
                    return setError("unknown-err");
            }
        }
    }

    const onSubmitVerifyLink = async (e) => 
    {
        e.preventDefault();
    
        if (!verifyLink.includes("https://account.live.com/Aliases") || !verifyLink.includes("cxt=ALS"))
            return setError("invalid-link");

        setStatus("step-3-attempt");
        setError("");

        infoForSecuring.account = activeSerial;
        infoForSecuring.newEmail = newEmail;
        infoForSecuring.verifyLink = verifyLink

        const response = await securer(infoForSecuring, "verifylink")

        if(!response)
        {
            setError("unknown-err");
            setStatus("pending");
        }

        const verifyLinkStatus = response.data;

        switch(verifyLinkStatus)
        {
            case "INVALID_VERIFY_LINK":
                return setError("invalid-link")
            case "MAKE_PRIMARY":
            case "SUCCESS":
                return setStep("success");
            case "INTERNAL_SERVER_ERROR":
            default:
                return setError("unknown-err")
        }
    }

    return (
        <>
        { isOpen ?
        <div className="securer-cont">
            <div className="securing-modal">
                <div className="securer-items">
                    <div className="securer-header">
                        <div className="securer-logo">
                            <img className="securer-img" src={logo} alt={`${ShopInfo.CompanyName} logo`}/>
                            <h2 className="securer-name">{ShopInfo.CompanyName}</h2>
                        </div>
                        <div className="securer-cancel" onClick={closeModal}>
                            X
                        </div>
                    </div>

                    {step === "step-1" ?
                        <div>
                            <h3 className="startprocess-header">Enter Email To Claim Account On</h3>
                            <p>Make sure this email is not registered as a microsoft account. Our system will check for you.</p>
                            <p>Do not eixt the process once you start. Doing so may require manual securing.</p>
                            {error  === "invalid-email"     ? <p className="securer-err">Invalid Email</p> : <></>}
                            {error  === "email-taken"       ? <p className="securer-err">Email is connected to a microsoft account.</p> : <></> }
                            {error  === "unknown-err"       ? <p className="securer-err">Unknown error. Please try again, if the issue persist, create a ticket in the Discord Server.</p> : <></> }
                            {error  === "account-locked"    ? <p className="securer-err">Account locked. Join the discord and create a ticket for a replacement.</p> : <></> }
                            {status === "step-1-attempt"    ? <p className="securer-pending">Process started. Do NOT exit. Estimated time 15 - 25 sec</p> : <></> }

                            <form className="securer-form" onSubmit={onSubmitStartProcess}>
                                <input className="startprocess-input" placeholder="Ex. tula392@gmail.com" type="email" value={newEmail} onChange={handleEmailChange}/>
                                <div className="startprocess-actions-cont">
                                    {status === "step-1-attempt" ?
                                    <button className="securer-loading">
                                        <span className="securer-loading-msg">Starting</span>
                                        <div className="loader"></div>
                                    </button> : 
                                    <input className="securer-submit-btn" value="Start Process" type="submit"/>}
                                </div>
                            </form>
                        </div> : <></>
                    }

                    {step === "failed" ?
                        <div>
                            <h3 className="startprocess-header">Error with Securer</h3>
                            <p>There was an error with securing the account. It must be manually secured via Email and Recovery Code.</p>
                            <p className="securer-mini-headers">Email</p>
                            <p className="securer-acc-details">{loginEmail}</p>
                            <p className="securer-mini-headers">Recovery Code</p>
                            <p className="securer-acc-details">{recoveryCode}</p>
                            <p className="securer-mini-headers">Instructions (Follow All Steps or Account may get Locked/Pulled and there will be no warranty)</p>
                            <p className="securer-acc-details">https://youtu.be/Ss0AltF0iGs</p>
                        </div> : <></> }

                    {step === "step-2" ?
                    <div>
                        <h2 className="startprocess-modal-header">
                            Enter Code
                        </h2>
                        <p>Check your email for a code from Microsoft. This is to add the email to your new account. If you don't see the code, check junk/spam, wait 5 minutes or try another email.</p>
                        {error  === "incorrect-code"    ? <p className="securer-err">Incorrect Code. Check the email you entered for six digit code from Microsoft.</p> : <></> }
                        {error  === "unknown-err"       ? <p className="securer-err">Unknown error. Please try again, if the issue persist, create a ticket in the Discord Server.</p> : <></> }
                        {status === "step-2-attempt"    ? <p className="securer-pending">Checking code. Do NOT exit. Estimated time 10 - 15 sec</p> : <></> }
                        <form className="securer-form" onSubmit={onSubmitCheckCode}>
                            <input className="startprocess-input" placeholder="Ex. 843923" value={code} onChange={handleCodeChange}/>
                            <div className="startprocess-actions-cont">
                                {status === "step-2-attempt" ? <button className="securer-loading">
                                    <span className="securer-loading-msg">Checking Code</span>
                                    <div className="loader"></div>
                                </button> :
                                <input className="securer-submit-btn" value="Submit Code" type="submit"/> }
                            </div>
                        </form>
                    </div> : <></> }

                    {step === "step-3" ?
                    <div>
                        <h2 className="startprocess-modal-header">
                            Enter verification link
                        </h2>
                        <p>Enter the verification link sent by Microsoft.<br/>Copy and paste this link to prevent any unwanted errors.</p>
                        {error  === "invalid-link"      ? <p className="securer-err">Invalid verification link. Copy and paste the first link sent by Microsoft.</p> : <></> }
                        {error  === "unknown-err"       ? <p className="securer-err">Unknown error. Please try again, if the issue persist, create a ticket in the Discord Server.</p> : <></> }
                        {status === "step-3-attempt"    ? <p className="securer-pending">Verifying Link. Do NOT exit. Estimated time 10 - 15 sec</p> : <></> }

                        <form className="securer-form" onSubmit={onSubmitVerifyLink}>
                            <input value={verifyLink} onChange={handleVerifyLinkChange} className="startprocess-input" placeholder="Ex. https://account.live.com/Aliases/Verify?aliasname=ryd6ersalmi%40mail.com&aliastype=Email&otc=*DkilhwEpKeweSTr0xBvxAy8jcXi8ovCmiYSqWGF90mOLBJWXMrNsXtIrKXG!!x7l18Q2jD2yE6glJy0j5tdL6A"/>
                            <div className="startprocess-actions-cont">
                                {status === "step-3-attempt" ? <button className="securer-loading">
                                    <span className="securer-loading-msg">Verifying Link</span>
                                    <div className="loader"></div>
                                </button> : 
                                <input className="securer-submit-btn" value="Submit Link" type="submit"/> }
                            </div>
                        </form>
                    </div> : <></> }

                    {step === "success" ? 
                        <div>
                            <h2 className="startprocess-modal-header">
                                Successfully Claimed
                            </h2>
                            <p>You can now login into Minecraft with your new email</p>
                            <p className="securer-acc-details">{newEmail}</p>
                            <p className="securer-mini-headers">What is my password?</p>
                            <p className="securer-acc-details"><b>Option 1. </b>Reset it when you try sign in.</p>
                            <p className="securer-acc-details"><b>Option 2. </b>Login by sending a code to your email.</p>
                            <p className="securer-mini-headers">What's next?</p>
                            <p className="securer-acc-details">Go to https://account.live.com/names/manage and remove emails that don't belong to you.</p>
                        </div> : <></> }
                </div>

                    {step === "step-1" ? 
                        <div className="securer-help">
                            <h3 className="securer-help-header">
                                How to check if email is registered?
                            </h3>
                            <img className="securer-help-img" src={validEmail} alt="valid email help"/>
                            <p className="securer-help-footer">
                                If this error appears. That means your email is not registered to another microsoft account.
                            </p>
                        </div> : <></> }

                    {step === "step-2" ? 
                    <div className="securer-help">
                        <h3 className="securer-help-header">
                            Where do I find the code?
                        </h3>
                        <img className="securer-help-img" src={checkCode} alt="check code help"/>
                        <p className="securer-help-footer">
                            Check for a 6 digit code sent by Microsoft.
                        </p>
                    </div> : <></> }

                    {step === "step-3" ? 
                    <div className="securer-help">
                        <h3 className="securer-help-header">
                            Where do I find this link?
                        </h3>
                        <img className="securer-help-img" src={verifyEmail} alt="verify email help"/>
                        <p className="securer-help-footer">
                            Copy and paste the whole first link that microsoft sent
                        </p>
                    </div> : <></> }
            </div>
        </div> : <></>}
        </>
    )
};