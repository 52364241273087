import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useState }      from "react";

import paypal                       from "../../images/paypal.webp";
import cardlogo                     from "../../images/cardlogo.webp";
import cashapp                      from "../../images/cashapp.webp";
import crypto                       from "../../images/crypto.webp";
import ideal                        from "../../images/ideal.png";
import vaz                          from "../../images/vaz.png"
import sadShoppingBag               from "../../images/sadshoppingbag.webp";

import { getCookie, setCookie }     from "../../util/cookies";
import { calculatePrice }           from "../../util/product";
import { parseJwt }                 from "../../util/jwt";

import { useAlert }                 from "../../context/AlertContext";
import { useCurrency }              from "../../context/CurrencyContext";

import { createInvoice }            from "../../services/invoice";
import { getProductByNameId }       from "../../services/product";
import { getCountry }               from "../../services/random";

import { AppConstants }             from "../../constants/AppConstants";
import { ShopProducts }             from "../../constants/AccountsInfo";

export default function CheckoutPage()
{
    const navigate                              = useNavigate();
    const location                              = useLocation();

    const { addAlert }                          = useAlert();
    const { currency }                          = useCurrency();
    const productNameId                         = window.location.pathname.split("/")[2];
    const searchParams =                        new URLSearchParams(location.search);
    const quantity                              = searchParams.get("quantity"); 

    const [ product, setProduct ]               = useState({})
    const [ account, setAccount ]               = useState({})

    const [ email, setEmail ]                   = useState("");
    const [ firstName, setFirstName ]           = useState("");
    const [ lastName, setLastName ]             = useState("");
    const [ country, setCountry ]               = useState("");
    const [ paymentMethod, setPaymentMethod ]   = useState("");

    const [ itemsPrices, setItemsPrice ]        = useState(0.00);
    const [ processingFees, setProcessingFees ] = useState(0.00);
    const [ discount, setDiscount]              = useState(0.00);

    const [ isDisabled, setIsDisabled ]         = useState(false);
    const [ checkingOut, setCheckingOut ]       = useState(false);
    const [ isAccount, setIsAccount ]           = useState(false);

    const checkout = async () =>
    {
        try {

            // Conditions where the checkout should not continue
            if (paymentMethod === "PayPal")
                return addAlert("Join the discord server and create a ticket to buy with PayPal. Otherwise, choose another payment method.", "warning", 7000);
            if (paymentMethod === "vaz")
                return window.open(AppConstants.DISCORD_REGULAR_INVITE, "_blank");
            if (!email || email === "")
                return addAlert("Fill out email.", "warning", 3000);
            if (paymentMethod === "")
                return addAlert("Pick a payment method.", "warning", 3000);
            
            // If it passes all the checks continue to creating the invoice
            setCheckingOut(true);
            const referrer              = getCookie("referrer");
            const customerStatus        = getCookie("CustomerStatus");

            // The order information
            const order =
            {
                paymentMethod: paymentMethod,
                referrer: referrer,
                productNameId: productNameId,
                quantity: quantity,
                email: email,
                firstName: firstName,
                lastName: lastName,
                customerStatus: customerStatus
            }

            const response = await createInvoice(order);

            const responseData = response.data;

            switch (responseData)
            {
                case '':
                case null:
                case undefined:
                    return addAlert("Please refresh the page and try again. Make sure you are not on a VPN. If it still doesn't work join the ticket and create a ticket.", "warning", 4000);
                case "BLACKLISTED":
                    setCookie("CustomerStatus", "blacklisted", 365)
                    return addAlert("You have been blacklisted. Join the discord server to appeal.", "warning", 6000);
                case "MINIMUM":
                    return addAlert("You must purchase the minimum", "warning", 4000);
                case 'PROXY':
                    return addAlert("VPN/Proxy detected! Turn it off to continue to checkout.", "warning", 4000)
                case 'CC_DISABLED':
                    return addAlert("Card payments down. Join the discord server to pay with PayPal, Zelle, Venmo or LTC.", "error", 6000);
                case 'INVALID_ACCESS':
                    return addAlert("You can't purchase under the minimum quantity.", "error", 6000)
                default:
                    if (responseData.includes("4WK4L3ET4E"))
                        setCookie("CustomerStatus", "common", 365)
                    window.location.href = responseData;
                    break;
            }            
        } catch (err) {
            console.log(err);
        }

        setCheckingOut(false);
    }

    const setPm = (pm) =>
    {
        if(isAccount)
        {
            if(pm === "Ideal" || pm === "Card")
            {
                setDiscount(0.00);
                setProcessingFees(itemsPrices * .05)
            }else{
                setProcessingFees(0.00);
            }
        }else{
            if (pm === "Crypto" || pm === "vaz")
            {
                setDiscount(itemsPrices * .1);
            } else {
                setDiscount(0.00);
            }
        }
        if (pm === "vaz")
        {
            addAlert("Join the Discord server and create a ticket to pay with these payment methods.", "warning", 4000)
        }
        setPaymentMethod(pm);
    }

    /**
     * On user input it will change the state of email which is the delivery email.
     * 
     * @param {*} e The onChange event.
     */
    const handleEmailChange = (e) => { setEmail(e.target.value) };

    /**
     * On user input it will change the state of first name which is stored for referring purposes.
     * 
     * @param {*} e The onChange event.
     */
    const handleFirstNameChange = (e) => { setFirstName(e.target.value) };

    /**
     * On user input it will change the state of last name which is stored for referring purposes.
     * 
     * @param {*} e The onChange event.
     */
    const handleLastNameChange = (e) => { setLastName(e.target.value) };

    const goBack = () => {
        if (isAccount) {
            navigate("/special-accounts");
        } else {
            navigate(`/products/${productNameId}`);
        }
    };

    useEffect(() =>
    {
        const UTOK = getCookie("UTOK");
        if (UTOK)
        {
            const payload = parseJwt(UTOK);

            setEmail(payload.username);
            setIsDisabled(!isDisabled);
        }

        const fetchData = async () =>
        {

            try {
                const countryResponse = await getCountry(productNameId);
                setCountry(countryResponse.data)
            } catch (err) {
                console.error(err);
            }

            try 
            {
                const productResponse = await getProductByNameId(productNameId);
                const productData = productResponse.data;
                if(productNameId.includes("-account") && !productNameId.includes("-accounts"))
                {
                    setIsAccount(true);
                    setAccount(productData.account);
                }
                setProduct(productData);
                setItemsPrice(calculatePrice(productData, quantity));
                
            } catch (err) {
                console.error(err);
            }

        };

        fetchData();
    }, []);

    return (
        <div className="checkout-page">
            <div className="checkout-cont">
                <div className="checkout-main-cont">
                    <div className="checkout-items-cont">
                        <div className="checkout-header-cont">
                            <div className="checkout-header-go-back-cont" onClick={goBack}>
                                <svg
                                    className="checkout-header-go-back"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="50 0 450 450"
                                    width="30px"
                                    height="30px"
                                    fill="white"
                                >
                                    <polygon points="352,128.4 319.7,96 160,256 319.7,416 352,383.6 224.7,256" />
                                </svg>
                            </div>
                            <h1 className="checkout-header-header">
                                Checkout
                            </h1>
                        </div>
                        {productNameId ?
                            <div className="checkout-items-products">
                                {
                                    productNameId !== "" ?
                                        <div className="o-p-i-p-item">
                                            <img className="o-p-i-p-info-img" src={isAccount ? `https://crafatar.com/avatars/${account.uuid}?overlay` : ShopProducts.get(productNameId)?.imageUrl} alt="Product" />
                                            <div className="o-p-i-p-info">
                                                <h4 className="o-p-i-p-header">{product.title}</h4>
                                                <div className="o-p-i-p-actions">
                                                    <div className="o-p-i-p-quantity">Quantity: <strong>{quantity}x</strong></div>
                                                    <div className="o-p-i-p-quantity">Price: <strong>{currency.symbol}{(product.price * currency.rate).toFixed(2)}</strong></div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="checkout-empty-cont">
                                            <div className="o-p-is-empty-msg">Your Order Is Empty</div>
                                            <img className="o-p-is-empty-img" src={sadShoppingBag} />
                                            <div className="o-p-is-browse-products">Browse our products <Link className="o-p-is-browse-products-link" to={"/"}>here</Link>!</div>
                                        </div>}
                            </div> : <></>}
                        <div>
                        </div>
                    </div>
                    <div className="checkout-page-right-cont">
                        <div className="checkout-page-payment-cont">
                            <div className="o-p-i-item o-p-order-summary">
                                <div className="o-p-o-s-header">Order Summary</div>
                                <div className="o-p-p-m-pricing">
                                    <div className="o-p-p-m-pricing-option">
                                        <div className="o-p-pricing-title">Price</div>
                                        <div className="o-p-pricing-value">{currency.symbol}{(itemsPrices * (currency.rate)).toFixed(2)}</div>
                                    </div>
                                    <div id="o-p-processing-fee" className="o-p-p-m-pricing-option">
                                        <div className="o-p-pricing-title">Processing Fees</div>
                                        <div className="o-p-pricing-value">{currency.symbol}{processingFees.toFixed(2)}</div>
                                    </div>
                                    <div id="o-p-processing-fee" className="o-p-p-m-pricing-option">
                                        <div className="o-p-pricing-title">Discount</div>
                                        <div className="o-p-pricing-value">{currency.symbol}{discount.toFixed(2)}</div>
                                    </div>
                                </div>
                                <div className="o-p-total-cont">
                                    <div className="o-p-p-m-pricing-option">
                                        <div className="o-p-t-title">Total:</div>
                                        <div className="o-p-t-value">{currency.symbol}{((itemsPrices - discount + processingFees) * currency.rate).toFixed(2)}</div>
                                    </div>
                                </div>
                                {/* <div className="o-p-p-m-promo">
                            <span className="o-p-p-m-promo-title">Coupon:</span>
                            <div className="o-p-p-m-promo-submit-cont">
                                <input className="o-p-p-m-promo-input" placeholder="Enter Coupon Code"/>
                                <button className="o-p-p-m-promo-submit">
                                    <img className="o-p-p-m-tag-img" src={tag} />
                                </button>
                            </div>
                        </div> */}
                            </div>
                            <div className="o-p-i-item">
                                <div id="o-p-customer-inputs-cont" className="o-p-customer-inputs-box">
                                    <div className="o-p-customer-input-cont">
                                        <div className="o-p-customer-inputs-header">Delivery Email</div>
                                        <input className={`o-p-customer-input ${!email || email === "" ? "o-p-customer-input-error" : ""}`} placeholder="Enter Email" onChange={handleEmailChange} value={email} disabled={isDisabled} />
                                    </div>
                                    {paymentMethod === "Card" && <div className={`o-p-customer-name`} >
                                        <div className="o-p-customer-input-cont">
                                            <div className="o-p-customer-inputs-header">First Name</div>
                                            <input className="o-p-customer-input" placeholder="Enter First Name" id="s-pm-first-name" onChange={handleFirstNameChange} value={firstName} />
                                        </div>
                                        <div className="o-p-customer-input-cont">
                                            <div className="o-p-customer-inputs-header">Last Name</div>
                                            <input className="o-p-customer-input" placeholder="Enter Last Name" id="s-pm-last-name" onChange={handleLastNameChange} value={lastName} />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="o-p-i-item">
                                <div className="o-p-p-m-header">Choose a payment method</div>
                                <div className="o-p-payment-methods">
                                    <div id="Card" className={`o-p-payment-method-option ${paymentMethod === 'Card' ? 'o-p-pm-selected' : ""}`} onClick={() => setPm("Card")}>
                                        <div className="o-p-pm-main-cont">
                                            <img className="o-p-payment-method-option-img" src={cardlogo} alt="Card"/>
                                            <div className="o-p-payment-method-option-text">Credit, Debit Card, Apple/Google Pay</div>
                                        </div>
                                    </div>
                                    <div id="Crypto" className={`o-p-payment-method-option ${paymentMethod === 'Crypto' ? 'o-p-pm-selected' : ""}`} onClick={() => setPm("Crypto")}>
                                        <div className="o-p-pm-main-cont">
                                            <img className="o-p-payment-method-option-img" src={crypto} alt="Crypto"/>
                                            <div className="o-p-payment-method-option-text">BTC/LTC/ETH</div>
                                        </div>
                                        {!isAccount ?
                                            <div className="o-p-pm-main-price-change o-p-pm-main-discount">
                                                -10%
                                            </div> :
                                            <div className="o-p-pm-main-price-change o-p-pm-main-discount">
                                                -5%
                                            </div>}
                                    </div>
                                    {country === "NL" &&
                                        <div id="Ideal" className={`o-p-payment-method-option ${paymentMethod === 'Ideal' ? 'o-p-pm-selected' : ""}`} onClick={() => setPm("Ideal")}>
                                            <div className="o-p-pm-main-cont">
                                                <img className="o-p-payment-method-option-img" src={ideal} alt="IDeal"/>
                                                <div className="o-p-payment-method-option-text">Ideal</div>
                                            </div>
                                        </div>}
                                    {country === "US" &&
                                        <div id="vaz" className={`o-p-payment-method-option ${paymentMethod === 'vaz' ? 'o-p-pm-selected' : ""}`} onClick={() => setPm("vaz")}>
                                            <div className="o-p-pm-main-cont">
                                                <img className="o-p-payment-method-option-img" src={vaz} alt="Zelle/Venmo/Apple Cash"/>
                                                <div className="o-p-payment-method-option-text">Zelle/Venmo/Apple Cash</div>
                                            </div>
                                            {!isAccount &&
                                                <div className="o-p-pm-main-price-change o-p-pm-main-discount">
                                                    -10%
                                                </div>}
                                        </div>}
                                </div>
                            </div>
                            <div className="o-p-i-item">
                                {checkingOut ? 
                                <div className="o-p-loading-pay">
                                    <div>Preparing Your Order</div>
                                    <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                </div> :
                                <div className="o-p-customer-checkout" onClick={checkout}>
                                    Proceed to Checkout
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}