
export default function WhatDoesFullAccessMean()
{
    return (
        <>
            <div className="support-questions-header">What Does "Full Access" Mean?</div>
            <article className="support-questions-solution">
                <section>
                    <h3 className="support-answers-section-header">Full Access (FA/MFA)</h3>
                    <p>This means you will have full access to the product. There is no other owners to the product except for you. All information and details to the product will be customizable to your own needs. That means you can change the email, password, username, skins, etc. on your product.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Not Full Access(NFA)</h3>
                    <p>This means you will not have full access. You will only be allowed to login.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}