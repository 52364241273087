export const ShopProducts = new Map([

    // Regular Full Access
    ["banned-fa", {
        "imageUrl": "/images/fabanned.webp",
        "productNameId": "banned-fa"
    }],
    
    ["unbanned-fa", {
        "imageUrl": "/images/faunbanned.webp",
        "productNameId": "unbanned-fa"
    }],

    ["fresh-fa", {
        "imageUrl": "/images/fanostats.webp",
        "productNameId": "fresh-fa"
    }],

    ["no-username-fa", {
        "imageUrl": "/images/fanostats.webp",
        "productNameId": "no-username-fa"
    }],

    ["java-bedrock-key", {
        "imageUrl": "/images/fanostats.webp",
        "productNameId": "java-bedrock-key"
    }],

    // Hypixel Accounts
    ["level-fa", {
        "imageUrl": "/images/falvl21.webp",
        "productNameId": "level-fa"
    }],

    ["lvl-vip-fa", {
        "imageUrl": "/images/favip.webp",
        "productNameId": "lvl-vip-fa"
    }],

    ["lvl-vip+-fa", {
        "imageUrl": "/images/favipplus.webp",
        "productNameId": "lvl-vip+-fa"
    }],

    ["lvl-mvp-fa", {
        "imageUrl": "/images/famvp.webp",
        "productNameId": "lvl-mvp-fa"
    }],

    ["mvp+-fa", {
        "imageUrl": "/images/famvpplus.webp",
        "productNameId": "mvp+-fa"
    }],

    ['mvp+-fa-100', {
        "productNameId": "mvp+-fa-100",
        "imageUrl": "/images/famvpplus100.webp"
    }],

    ['mvp+-fa-250', {
        "productNameId": "mvp+-fa-250",
        "imageUrl": "/images/famvpplus250.webp"
    }],

    // EXCLUSIVE
    ['beta-creation-fa', {
        "productNameId": "beta-creation-fa",
        "imageUrl": "/images/fabeta.webp"
    }],

    ['alpha-creation-fa', {
        "productNameId": "alpha-creation-fa",
        "imageUrl": "/images/faalpha.webp"
    }],

    ['prename-fa', {
        "productNameId": "prename-fa",
        "imageUrl": "/images/faprename.webp"
    }],

    // SKYBLOCK
    ['skyblock-coins', {
        "productNameId": "skyblock-coins",
        "imageUrl": "/images/skyblockcoins.webp"
    }],

    ['skyblock-50-fa', {
        "productNameId": "skyblock-50-fa",
        "imageUrl": "/images/faskyblocklvl50.webp"
    }],

    ["skyblock-fa", {
        "imageUrl": "/images/faskyblocklvl15.webp",
        "productNameId": "skyblock-fa"
    }],

    // CAPED ACCOUNTS
    ['migrator-cape-fa', {
        "productNameId": "migrator-cape-fa",
        "imageUrl": "/images/famigrator.webp"
    }],

    ['vanilla-cape-fa', {
        "productNameId": "vanilla-cape-fa",
        "imageUrl": "/images/favanilla.webp"
    }],

    ['mcc-cape-fa', {
        "productNameId": "mcc-cape-fa",
        "imageUrl": "/images/famcc.webp"
    }],

    ['15th-anniversary-cape-fa', {
        "productNameId": "15th-anniversary",
        "imageUrl": "/images/fa15thanniversary.webp"
    }],

    ['purple-heart-cape-fa', {
        "productNameId": "purple-heart-cape-fa",
        "imageUrl": "/images/fapurpleheart.webp"
    }],

    ['followers-cape-fa', {
        "productNameId": "followers-cape-fa",
        "imageUrl": "/images/fafollowers.webp"
    }],
    
    ['cherry-blossom-fa', {
        "productNameId": "cherry-blossom-fa",
        "imageUrl": "/images/facherryblossom.webp"
    }],

    ['mojang-office-fa', {
        "productNameId": "mojang-office-fa",
        "imageUrl": "/images/famojangoffice.webp"
    }],

    // Temporary Accounts
    ['xbox-gamepass-accounts', {
        "productNameId": "xbox-gamepass-accounts",
        "imageUrl": "/images/xboxgamepass.webp"
    }]
]);
