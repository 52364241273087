import { useEffect, useState }              from "react";
import { useNavigate, useSearchParams }     from "react-router-dom";

import { useAlert }                         from "../../context/AlertContext";

import { resetPassword }                    from "../../services/user";

import { getCookie }                        from "../../util/cookies";

export default function ResetPassword()
{
    const [searchParams]                        = useSearchParams();

    const [newPassword, setNewPassword]         = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { addAlert }                          = useAlert();

    const token                                 = searchParams.get("token");
    const navigate                              = useNavigate();

    useEffect(() => {
        // If there is a logged in user then bring them to the dashboard
        if (getCookie("UTOK"))
        {
            navigate("/users");
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            addAlert("Passwords do not match.", "warning", 3000);
            return;
        }

        const response = await resetPassword(token, newPassword);

        switch(response?.data?.message)
        {
            case "SUCCESS":
                addAlert("Password reset successful! Redirecting...", "error", 3000);
                setTimeout(() => navigate("/login"), 3000);
                break;
            case "INVALID_OR_EXPIRED_TOKEN":
                return addAlert("Invalid Password Reset Link. Request a new one.", "error", 3000);
            default:
                return addAlert("Error resetting password. Please try again or contact support.", "error", 3000);
        }
    };

    return (
        <div>
            <form className="member-login-cont" onSubmit={handleSubmit}>
                <div className="s-r-header-cont">
                    <h3 className="s-r-header">Reset Password</h3>
                    <p className="s-r-h-msg">Enter your new password below.</p>
                </div>
                <div className="s-r-input-cont">
                    <h4 className="s-r-input-header">New Password</h4>
                    <input
                        className="s-r-input"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="s-r-input-cont">
                    <h4 className="s-r-input-header">Confirm Password</h4>
                    <input
                        className="s-r-input"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <input className="s-r-submit-btn" value="Reset Password" type="submit" />
            </form>
        </div>
    );
}