
export default function IPaidForMyProductHowDoIClaimIt()
{
    return (
        <>
            <div className="support-questions-header">I Paid For My Product. How do I claim it?</div>
            <article className="support-questions-solution">
                <p>Great! You've chose us. First we want to thank you for your decision!</p>
                <section>
                    <h3 className="support-answers-section-header">1. Email</h3>
                    <p>If you paid for your product with Stripe, Crypto or CashApp. You should have receive an email containing the details for the product. Be sure to check your spam if you can't find it in your regular inbox.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">2. Account Dashboard</h3>
                    <p>If you were logged in during your purchase, the order should now be linked to your account! Navigate to "Dashboard" to find information about your account and to view all your recent orders. Once you find the order you are looking for, click "View Order" to view the product.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}