import { Link } from "react-router-dom";
import { useCurrency } from "../../context/CurrencyContext";
import { useProducts } from "../../context/ProductsContext";
import { useState, useEffect } from "react";

export default function Product({ product }) {
    const { currency } = useCurrency();
    const { loading } = useProducts();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState(product.imageUrl || "/default-image.jpg");

    useEffect(() => {
        const img = new Image();
        img.src = product.imageUrl || "/default-image.jpg";
        img.onload = () => setImageLoaded(true);
    }, [product.imageUrl]);

    return (
        <Link className="grid-item" to={`/products/${product.productNameId}`}>
            <div className="product-img-holder">
                {!imageLoaded ? (
                    <div className="image-placeholder">Loading image...</div>
                ) : (
                    <img
                        className="product-thumbnail-img"
                        src={imageSrc}
                        alt={product.title}
                        loading="eager"
                        fetchpriority="high"
                    />
                )}
            </div>
            <div className="grid-item-info">
                {loading ? 
                    <div className="products-loading-msg">
                        Loading product...
                    </div> 
                :
                <>
                    <div className="grid-item-header">
                        <div className="g-i-h-title">{product.title}</div>
                    </div>
                    <div className="product-mini-details">
                        <div className="product-price">
                            {currency.symbol}{(product.price * currency.rate).toFixed(2)}
                        </div>
                        <div className="product-price-real">
                            {currency.symbol}{((product.price * currency.rate) * 1.35).toFixed(2)}
                        </div>
                    </div>
                </>}
            </div>
        </Link>
    );
}