
export default function DigitalItemThatIPurchasedDoesntWork()
{
    return (
        <>
            <div className="support-questions-header">Digital Item That I Purchased Doesn't Work or Stopped Working. What do I Do?</div>
            <article className="support-questions-solution">
                <p>Check the product you purchased and read all the instructions on the product page and the instructions sent to you when you received the product.</p>
                <section>
                    <h3 className="support-answers-section-header">1. Account is locked.</h3>
                    <p>If the account is locked and you followed all the instructions sent to you in your order link and you are 100% sure you did not do something to cause the lock, contact our support and explain in <b>details</b> what happened.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">2. Credentials or information is incorrect.</h3>
                    <p>Please ensure you are copying and pasting the credentials/details sent to you and follow all steps provided.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">3. Product doesn't have Minecraft.</h3>
                    <p>If you purchased a Full Access product. Make sure you are logging in with the email you set after purchasing.</p>
                    <p>If you purchased a temporary access, ensuring you are following the instructions sent to you as some products may have a specific way to access them.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">4. Trouble Shooting</h3>
                    <p>The first thing to always try when something is not working is to retry.</p>
                    <ul className="support-answers-ul">
                        <li><b>Accounts:</b> Log out and log back in.</li>
                        <li><b>Software/Programs:</b> Uninstall and reinstall the software/program also make sure it is updated to the latest version.</li>
                        <li><b>Keys:</b> Refresh the page and re-enter your key. If there are error messages, read the error messages.</li>
                    </ul>
                </section>
                <section>
                    <h3 className="support-answers-section-header">5. VPN/Proxy</h3>
                    <p>If you are using a VPN or Proxy. Switch the server or disconnect. If you aren't then connect to a new server. Refresh your browser or reattempt.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">6. Contact the provider for the product.</h3>
                    <p>Some issues may require you to contact the provider of the product. For example, if you purchased a steam gift card and there is an unknown error, you may need to contact steam's support.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}