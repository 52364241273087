import { Link, Route, Routes }                          from "react-router-dom";

import aboutus                                          from "../../images/aboutus.webp"
import popularquestions                                 from "../../images/popularquestions.webp"
import payments                                         from "../../images/payments.webp"
import warranty                                         from "../../images/warranty.webp"
import products                                         from "../../images/products.webp"

import { AppConstants }                                 from "../../constants/AppConstants";
import { copyToClipboard }                              from "../../util/clipboard";
import SupportBreadCrumb                                from "./SupportBreadCrumb";
import PopularQuestions                                 from "./popularquestion/PopularQuestions";
import PaymentQuestions                                 from "./payments/PaymentQuestions";
import IPaidForMyProductHowDoIClaimIt                   from "./payments/IPaidForMyProductHowDoIClaimIt";
import IDidNotReceiveAnEmailAfterPayingWhatNow          from "./payments/IDidNotReceiveAnEmailAfterPayingWhatNow";
import MyPaymentFailedOrGotDeclined                     from "./payments/MyPaymentFailedOrGotDeclined";
import HowCanIPayWithAmazonGiftCard                     from "./payments/HowCanIPayWithAmazonGiftCard";
import AfterIPayHowLongDoINeedToWaitToReceiveMyProduct  from "./payments/AfterIPayHowLongDoINeedToWaitToReceiveMyProduct";
import DifficultyDecidingBetweenProducts                from "./products/DifficultyDecidingBetweenProducts";
import DoYouHaveAccountsWithSpecificStatsOnHypixel      from "./products/DoYouHaveAccountsWithSpecificStatsOnHypixel";
import DoYouSellMineconCapes                            from "./products/DoYouSellMineconCapes";
import AboutUsQuestions                                 from "./aboutus/AboutUsQuestions";
import HowDoesThisWork                                  from "./aboutus/HowDoesThisWork";
import IsAltsGreatLegitAndSafeToBuyFrom                 from "./aboutus/IsAltsGreatLegitAndSafeToBuyFrom";
import WhoAreWe                                         from "./aboutus/WhoAreWe";
import WarrantyQuestions                                from "./warranties/WarrantyQuestions";
import HowLongDoesTheWarrantyLastAfterPurchasing        from "./warranties/HowLongDoesTheWarrantyLastAfterPurchasing";
import WhatIsTheRefundPolicy                            from "./warranties/WhatIsTheRefundPolicy";
import DigitalItemThatIPurchasedDoesntWork              from "./popularquestion/DigitalItemThatIPurchasedDoesntWorkOrStoppedWorkingWhatDoIDo";
import ProductQuestions                                 from "./products/ProductQuestions";
import WhatDoesFullAccessMean                           from "./products/WhatDoesFullAccessMean";

export default function Support()
{
    return (
    <>
        <div className="support-page-cont">
            <div className="support-navbar">
                <div className="support-navbar-inner">
                    <Link to={"/support"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={popularquestions} alt="popular questions"/>
                        <div className="support-navbar-item-text">Popular Questions</div>
                    </Link>
                    <Link to={"/support/payments"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={payments} alt="payment questions"/>
                        <div className="support-navbar-item-text">Payments</div>
                    </Link>
                    <Link to={"/support/products"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={products} alt="product questions"/>
                        <div className="support-navbar-item-text">Products</div>
                    </Link>
                    <Link to={"/support/aboutus"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={aboutus} alt="about us section"/>
                        <div className="support-navbar-item-text">About Us</div>
                    </Link>
                    <Link to={"/support/warranties"} className="support-navbar-item">
                        <img className="support-navbar-item-img" src={warranty} alt="warranty section"/>
                        <div className="support-navbar-item-text">Warranty</div>
                    </Link>
                </div>
            </div>
            <div className="support-main">
                <div className="support-answer-page">
                    <SupportBreadCrumb/>
                    <Routes>
                        <Route path="/" element={<PopularQuestions />}/>
                        <Route path="/payments" element={<PaymentQuestions />}/>
                        <Route path="/payments/i-paid-for-my-product-how-do-i-claim-it" element={<IPaidForMyProductHowDoIClaimIt/>} />
                        <Route path="/payments/i-did-not-receive-an-email-after-paying" element={<IDidNotReceiveAnEmailAfterPayingWhatNow/>} />
                        <Route path="/payments/my-payment-failed-or-got-declined" element={<MyPaymentFailedOrGotDeclined />} />
                        <Route path="/payments/how-can-i-pay-with-amazon-gift-cards" element={<HowCanIPayWithAmazonGiftCard />} />
                        <Route path="/payments/after-i-pay-how-long-do-i-need-to-wait-to-receive-my-product" element={<AfterIPayHowLongDoINeedToWaitToReceiveMyProduct/>} />

                        <Route path="/products" element={<ProductQuestions />}/>
                        <Route path="/products/what-does-full-access-mean" element={<WhatDoesFullAccessMean />} />
                        <Route path="/products/i-am-having-difficulties-deciding-which-product-i-should-purchase" element={<DifficultyDecidingBetweenProducts />} />
                        <Route path="/products/do-you-have-accounts-with-specific-stats-on-hypixel" element={<DoYouHaveAccountsWithSpecificStatsOnHypixel />} />
                        <Route path="/products/do-you-sell-minecon-capes" element={<DoYouSellMineconCapes />}/>

                        <Route path="/aboutus"  element={<AboutUsQuestions />}/>
                        <Route path="/aboutus/how-does-this-work" element={<HowDoesThisWork/>} />
                        <Route path="/aboutus/is-alts-great-legit-and-safe-to-buy-from" element={<IsAltsGreatLegitAndSafeToBuyFrom/>} />
                        <Route path="/aboutus/who-are-we" element={<WhoAreWe/>} />

                        <Route path="/warranties" element={<WarrantyQuestions/>}/>
                        <Route path="/warranties/how-long-does-the-warranty-last-after-purchasing" element={<HowLongDoesTheWarrantyLastAfterPurchasing/>} />
                        <Route path="/warranties/what-is-the-refund-policy" element={<WhatIsTheRefundPolicy/>} />

                        <Route path="/popular-questions" element={<PopularQuestions />}/>
                        <Route path="/popular-questions/digital-item-that-i-purchased-doesnt-work-or-stopped-working-what-do-i-do" element={<DigitalItemThatIPurchasedDoesntWork />}/>
                    </Routes>
                </div>
                <div className="support-contact-cont">
                    <div className="support-contact-info-header">Get in touch with support</div>
                    <div className="support-contact-item">
                        <div className="support-contact-item-header">Contact via Discord.</div>
                        <a className="support-contact-discord" href={AppConstants.DISCORD_REGULAR_INVITE} target="_blank" rel="noreferrer">Join the Discord Server</a>
                    </div>
                    <div className="support-contact-item">
                        <div className="support-contact-item-header">Email us</div>
                        <div className="support-contact-email" onClick={(e) => copyToClipboard(e.target.innerText)}>support@altsgreat.com</div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};