import { AppConstants } from "../constants/AppConstants";

export const setCookie = (cookieName, cookieValue, expirationDays) =>
{
    let date = new Date();
    date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + date.toUTCString();
    if(AppConstants.WEB_URL.includes("localhost"))
    {
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + `;path=/`;
    }else{
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + `;path=/;domain=.altsgreat.com`;
    }
}

export const getCookie = (cookieName) => {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return ;
}

export const deleteCookie = (name) => {
    if(AppConstants.WEB_URL.includes("localhost"))
    {
        document.cookie = name + `=; Max-Age=-99999999; path=/`;
    }else{
        document.cookie = name + `=; Max-Age=-99999999; path=/; domain=.altsgreat.com`;
    }
}