import { useState }                     from "react";
import { Helmet }                       from "react-helmet-async";

import { useAlert }                     from "../../context/AlertContext";
import { useCurrency }                  from "../../context/CurrencyContext";

import coins                            from "../../images/coins.webp";

export default function SkyblockCoinsPage()
{
    const { addAlert }                          = useAlert();
    const { currency }                          = useCurrency();

    const [paymentMethod, setPaymentMethod]     = useState("Crypto");
    const [amount, setAmount]                   = useState("");
    const [price, setPrice]                     = useState(0.0);
    const [displayedPrice, setDisplayedPrice]   = useState(0.0);

    const purchase = () => {
        addAlert(
            "Since coins need to be delivered manually, to purchase coins, please join the discord server and create a ticket for 'Skyblock Coins'",
            "warning",
            5000
        );
    };

    const changeQuantity = (e) => {
        const coinsInput = e.target.value;

        if (/^\d*$/.test(coinsInput)) {
            setAmount(coinsInput);
            const enteredAmount = parseInt(coinsInput);
            let tempPrice = price;
            if (isNaN(enteredAmount)) {
                tempPrice = 0;
                setPrice(0);
            } else if (enteredAmount >= 400) {
                tempPrice = enteredAmount * 0.050;
                setPrice(tempPrice);
            } else if (enteredAmount > 2000) {
                tempPrice = enteredAmount * 0.045;
                setPrice(tempPrice);
            }
            fixPrice(tempPrice, paymentMethod);
        }
    };

    const fixPrice = (priceArg, paymentMethod) => {
        if (paymentMethod === "Card") setDisplayedPrice(priceArg * 1.2);
        else if (paymentMethod === "Amazon") setDisplayedPrice(priceArg * 1.05);
        else if (paymentMethod === "Crypto") setDisplayedPrice(priceArg * 0.95);
        else setDisplayedPrice(priceArg);
    };

    const changePaymentMethod = (e) =>
    {
        let selectedPaymentMethod = e.currentTarget.id;

        setPaymentMethod(selectedPaymentMethod);
        fixPrice(price, selectedPaymentMethod);
    };

    return (
        <div className="skyblock-page-cont">
            <Helmet>
                <title>Hypixel Skyblock Coins | Buy Now!</title>
                <meta property="og:title" content="Hypixel Skyblock Coins | Buy Now!" />
                <meta property="og:site_name" content="Hypixel Skyblock Coins | Buy Now!" />
                <meta
                    name="description"
                    content="Save thousands of hours grinding on Hypixel Skyblock and buy Skyblock coins from us for the cheapest prices. Undetectable Methods."
                />
            </Helmet>
            <div className="p-p-main">
                <div className="s-c-info-cont">
                    <div>
                        <div className="s-p-header">
                            <img className="skyblock-coin-logo" src={coins} alt="Hypixel Skyblock Coin Logo"/>
                            <h1 className="s-p-header-text">Hypixel Skyblock Coins</h1>
                        </div>
                        <div className="s-p-header-msg">
                            Save thousands of hours grinding on Skyblock and enjoy our coins which are delivered with the safest method to exist.
                        </div>
                    </div>

                    <div className="s-c-calc-cont">
                        <div className="s-c-calc-prices">
                            <div>
                                <h3 className="s-c-calc-header">Coin Calculator</h3>
                                <div className="s-c-calc-amount-cont">
                                    <div className="s-c-calc-input-cont">
                                        <input
                                            className="s-c-calc-input"
                                            placeholder="Enter Amount"
                                            value={amount}
                                            onChange={changeQuantity}
                                        />
                                        <div className="s-c-calc-million">M</div>
                                    </div>
                                </div>
                                <p className="s-c-calc-amount-msg">
                                    <i>The amount entered is calculated in millions (M).</i>
                                </p>
                            </div>
                            <div className="s-c-calc-total-cont">
                                <h3>
                                    Total Price: <span className="s-c-calc-total-amount">{currency.symbol}{(displayedPrice * currency.rate).toFixed(2)}</span>
                                </h3>
                                <div className="s-c-calc-purchase" onClick={purchase}>
                                    Purchase
                                </div>
                            </div>
                        </div>

                        <div className="s-c-calc-payment-methods">
                            <div className="s-c-calc-sub-header">Payment Methods:</div>
                            <div className="s-c-calc-p-m-options">
                                <div id="Crypto" className={`s-c-calc-p-m-option ${paymentMethod === `Crypto` ? 's-c-pm-chosen' : "" }`} onClick={changePaymentMethod}>
                                    Crypto <span className="s-c-calc-option-discount">(-5%)</span>
                                </div>
                                <div id="Venmo" className={`s-c-calc-p-m-option ${paymentMethod === `Venmo` ? 's-c-pm-chosen' : "" }`} onClick={changePaymentMethod}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 50 50" fill="none" stroke="currentColor"><path d="M 9 4 C 6.2504839 4 4 6.2504839 4 9 L 4 41 C 4 43.749516 6.2504839 46 9 46 L 41 46 C 43.749516 46 46 43.749516 46 41 L 46 9 C 46 6.2504839 43.749516 4 41 4 L 9 4 z M 9 6 L 41 6 C 42.668484 6 44 7.3315161 44 9 L 44 41 C 44 42.668484 42.668484 44 41 44 L 9 44 C 7.3315161 44 6 42.668484 6 41 L 6 9 C 6 7.3315161 7.3315161 6 9 6 z M 35.015625 12 A 1.0001 1.0001 0 0 0 34.757812 12.029297 L 26.757812 14.029297 A 1.0001 1.0001 0 0 0 26.101562 15.439453 C 26.541577 16.340118 27 17.494776 27 19 C 27 20.929343 25.846954 23.896821 24.576172 26.552734 L 22.992188 13.875 A 1.0001 1.0001 0 0 0 21.875 13.005859 L 13.875 14.007812 A 1.0001 1.0001 0 0 0 13.009766 15.134766 L 16.009766 37.134766 A 1.0001 1.0001 0 0 0 17 38 L 28 38 A 1.0001 1.0001 0 0 0 28.789062 37.613281 C 32.930502 32.283606 37 24.760794 37 18.585938 C 37 16.740219 36.951507 14.251453 35.849609 12.472656 A 1.0001 1.0001 0 0 0 35.015625 12 z M 34.34375 14.195312 C 34.76935 15.335743 35 17.108033 35 18.585938 C 35 23.718015 31.33623 30.919236 27.476562 36 L 17.873047 36 L 15.126953 15.867188 L 21.132812 15.117188 L 23.007812 30.125 A 1.0001 1.0001 0 0 0 24.847656 30.53125 C 26.763056 27.482075 29 22.588998 29 19 C 29 17.659506 28.691096 16.60617 28.34375 15.695312 L 34.34375 14.195312 z"></path></svg> Venmo</div>
                                <div id="Zelle" className={`s-c-calc-p-m-option ${paymentMethod === `Zelle` ? 's-c-pm-chosen' : "" }`} onClick={changePaymentMethod}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 45 45" fill="#818181"><path d="M35,42H13c-3.866,0-7-3.134-7-7V13c0-3.866,3.134-7,7-7h22c3.866,0,7,3.134,7,7v22 C42,38.866,38.866,42,35,42z"></path><path fill="#fff" d="M17.5,18.5h14c0.552,0,1-0.448,1-1V15c0-0.552-0.448-1-1-1h-14c-0.552,0-1,0.448-1,1v2.5	C16.5,18.052,16.948,18.5,17.5,18.5z"></path><path fill="#fff" d="M17,34.5h14.5c0.552,0,1-0.448,1-1V31c0-0.552-0.448-1-1-1H17c-0.552,0-1,0.448-1,1v2.5	C16,34.052,16.448,34.5,17,34.5z"></path><path fill="#fff" d="M22.25,11v6c0,0.276,0.224,0.5,0.5,0.5h3.5c0.276,0,0.5-0.224,0.5-0.5v-6c0-0.276-0.224-0.5-0.5-0.5	h-3.5C22.474,10.5,22.25,10.724,22.25,11z"></path><path fill="#fff" d="M22.25,32v6c0,0.276,0.224,0.5,0.5,0.5h3.5c0.276,0,0.5-0.224,0.5-0.5v-6c0-0.276-0.224-0.5-0.5-0.5	h-3.5C22.474,31.5,22.25,31.724,22.25,32z"></path><path fill="#fff" d="M16.578,30.938H22l10.294-12.839c0.178-0.222,0.019-0.552-0.266-0.552H26.5L16.275,30.298	C16.065,30.553,16.247,30.938,16.578,30.938z"></path></svg>Zelle</div>
                                <div id="Revolut" className={`s-c-calc-p-m-option ${paymentMethod === `Revolut` ? 's-c-pm-chosen' : "" }`} onClick={changePaymentMethod}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512" fill="#818181"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM160 152c0-13.3 10.7-24 24-24l88 0c44.2 0 80 35.8 80 80c0 28-14.4 52.7-36.3 67l34.1 75.1c5.5 12.1 .1 26.3-11.9 31.8s-26.3 .1-31.8-11.9L268.9 288 208 288l0 72c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-96 0-112zm48 88l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0 0 64z"/></svg>Revolut</div>
                                <div id="AppleCash" className={`s-c-calc-p-m-option ${paymentMethod === `AppleCash` ? 's-c-pm-chosen' : "" }`} onClick={changePaymentMethod}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-brand-apple-filled"><path d="M15.079 5.999l.239 .012c1.43 .097 3.434 1.013 4.508 2.586a1 1 0 0 1 -.344 1.44c-.05 .028 -.372 .158 -.497 .217a4.15 4.15 0 0 0 -.722 .431c-.614 .461 -.948 1.009 -.942 1.694c.01 .885 .339 1.454 .907 1.846c.208 .143 .436 .253 .666 .33c.126 .043 .426 .116 .444 .122a1 1 0 0 1 .662 .942c0 2.621 -3.04 6.381 -5.286 6.381c-.79 0 -1.272 -.091 -1.983 -.315l-.098 -.031c-.463 -.146 -.702 -.192 -1.133 -.192c-.52 0 -.863 .06 -1.518 .237l-.197 .053c-.575 .153 -.964 .226 -1.5 .248c-2.749 0 -5.285 -5.093 -5.285 -9.072c0 -3.87 1.786 -6.92 5.286 -6.92c.297 0 .598 .045 .909 .128c.403 .107 .774 .26 1.296 .508c.787 .374 .948 .44 1.009 .44h.016c.03 -.003 .128 -.047 1.056 -.457c1.061 -.467 1.864 -.685 2.746 -.616l-.24 -.012z" fill="currentColor" stroke-width="0"></path><path d="M14 1a1 1 0 0 1 1 1a3 3 0 0 1 -3 3a1 1 0 0 1 -1 -1a3 3 0 0 1 3 -3z" fill="currentColor" stroke-width="0"></path></svg>Apple Cash (USA Only)</div>
                                <div id="Card" className={`s-c-calc-p-m-option ${paymentMethod === `Card` ? 's-c-pm-chosen' : "" }`} onClick={changePaymentMethod}>
                                    Debit/Credit Card <span className="s-c-calc-option-fee">(+15%)</span>
                                </div>
                                <div id="Amazon" className={`s-c-calc-p-m-option ${paymentMethod === `Amazon` ? 's-c-pm-chosen' : "" }`} onClick={changePaymentMethod}>
                                    Amazon Gift Card <span className="s-c-calc-option-fee">(+5%)</span>
                                </div>
                            </div>
                        </div>
                        <div className="s-c-calc-rates">
                            <div className="s-c-calc-sub-header">Rates</div>
                            <div className="s-c-calc-rates-list">
                                <div>• $0.050/mil (400m+)</div>
                                <div>• $0.045/mil (2000m+)</div>
                            </div>
                        </div>
                        <div className="s-c-calc-extra-info">
                            <div className="s-c-calc-sub-header">More About This Product</div>
                            <div className="s-c-calc-rates-list">
                                <div>• Must be at least level 50 on Skyblock.</div>
                                <div>• 100% authentic coins (Not Duped/Fake).</div>
                                <div>• 24/7 customer service</div>
                                <div>• Extremely secure transfer method.</div>
                            </div>
                        </div>
                        <div className="s-c-calc-extra-info">
                            <div className="s-c-calc-sub-header">Warranty</div>
                            <div className="s-c-calc-rates-list">
                                <div>• Due to Hypixel's trading rules, there is no warranty for this product, but we ensure the safest delivery method.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
