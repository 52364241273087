import React from "react";

export default function XboxLogo({ width, height, color }) {
  return (
    <svg
      version="1.1"
      id="fi_1321"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 25.732 25.732"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill={color}
          d="M5.733,2.183C5.72,2.193,5.71,2.204,5.698,2.214c0.073-0.077,0.178-0.16,0.247-0.205
		C7.959,0.7,10.286,0,12.868,0c2.415,0,4.677,0.667,6.608,1.827c0.14,0.083,0.522,0.345,0.719,0.583v0.001
		c-1.863-2.054-7.339,2.353-7.339,2.353c-1.894-1.46-3.636-2.455-4.933-2.809C6.839,1.658,6.089,1.906,5.733,2.183z M22.305,4.125
		c-0.057-0.063-0.119-0.122-0.176-0.187c-0.471-0.517-1.05-0.642-1.572-0.608c-0.475,0.149-2.67,0.94-5.375,3.494
		c0,0,3.045,2.961,4.91,5.988c1.863,3.027,2.977,5.406,2.292,8.708c2.079-2.285,3.347-5.321,3.347-8.655
		C25.731,9.491,24.432,6.42,22.305,4.125z M17.657,14.105c-0.826-0.927-2.055-2.255-3.678-3.869
		c-0.355-0.353-0.73-0.721-1.124-1.102c0,0-0.593,0.593-1.365,1.373v-0.001c-0.988,0.998-2.269,2.3-2.98,3.06
		c-1.266,1.351-4.893,5.594-5.097,7.973c0,0-0.807-1.887,0.964-6.239c1.157-2.845,4.653-7.117,6.113-8.509
		c0,0-1.334-1.468-3.008-2.482l-0.01-0.003c0,0-0.019-0.015-0.049-0.034c-0.805-0.48-1.684-0.85-2.53-0.899
		C4.029,3.434,3.482,4.066,3.482,4.066c-2.159,2.302-3.48,5.396-3.48,8.8c0,7.105,5.76,12.866,12.866,12.866
		c3.771,0,7.164-1.626,9.518-4.211c-0.001-0.004-0.271-1.7-2.002-4.133C19.977,16.818,18.49,15.039,17.657,14.105z"
        />
      </g>
    </svg>
  );
};