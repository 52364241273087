import "../../styles/filter.css";

import React, { useEffect }     from "react";

import { getCookie, setCookie } from "../../util/cookies";
import { getLocalStorageItem }  from "../../util/localstorage";

import { useProducts }          from "../../context/ProductsContext";

import MinecraftSEO             from "../seo/MinecraftSEO";

import FeaturedProduct          from "./FeaturedProduct";
import ProductSection           from "../products/ProductSection";

export default function Home()
{
    const { products }          = useProducts();

    const handleScroll = (sectionId) => {
        const element = document.getElementById(sectionId);

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        let referrer;
        try {
            if (getLocalStorageItem("visited")) {
                referrer = "default";
                setCookie("referrer", referrer, 14);
                window.localStorage.removeItem("visited");
            } else if (!getCookie("referrer")) {
                const urlParams = new URLSearchParams(window.location.search);
                referrer = urlParams.get("r") || "default";
                setCookie("referrer", referrer, 14);
            }
        } catch (err) {
            console.log(err);
        }

        document.title = "Great Alts | Minecraft Alts | Buy the Best and Cheapest Minecraft Accounts & Keys";

    const updateMetaTag = (name, content) => {
      let metaTag = document.querySelector(`meta[name='${name}']`);
      if (metaTag) {
        metaTag.setAttribute("content", content);
      } else {
        metaTag = document.createElement("meta");
        metaTag.setAttribute("name", name);
        metaTag.setAttribute("content", content);
        document.head.appendChild(metaTag);
      }
    };

    updateMetaTag("description", "We offer lifetime warranties, the cheapest prices, and the highest quality keys and alts. Purchase and buy from us today!");
    updateMetaTag("viewport", "width=device-width, initial-scale=1.0");
    }, []);

    return (
        <>
            <div className="store-cont">
                <div className="slideshow-cont">
                    <div className="slideshow">
                        <div className="slideshow-screener">
                            <div className="slideshows-words">
                                <h3 className="slideshow-header">Pick The Minecraft Account That Matches Your Needs</h3>
                                <p className="slideshow-message">
                                    The #1 rated alt shop in the world. Offering lifetime warranties, the cheapest prices, and the highest quality keys and alts. Your satisfaction is guaranteed while shopping with us!
                                </p>
                                <div className="slideshow-button" onClick={() => handleScroll("best-sellers")}>
                                    Browse Products
                                </div>
                            </div>
                            <div  className="featured-product-cont">
                                <div className="featured-product-slider">
                                    { products.get("prename-fa") && <FeaturedProduct product={products.get("prename-fa")}/>}
                                    { products.get("xbox-gamepass-accounts") && <FeaturedProduct product={products.get("xbox-gamepass-accounts")}/>}
                                    { products.get("mvp+-fa") && <FeaturedProduct product={products.get("mvp+-fa")}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProductSection />
            </div>
            <MinecraftSEO />
        </>
    )
}