import { RouteConstants } from "../constants/AppConstants";
import api from "./apiConfig"

/**
 * Sends a request to get the country.
 * 
 * @returns The response from the backend
 */
export const getCountry = async (productNameId) => 
{
    try {
        const response = await api.get(`${RouteConstants.RANDOM_URL}/country/${productNameId}`);
        return response;
    }catch(err){
        return err.response;
    }
}