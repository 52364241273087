import { useEffect, useState }  from "react";
import { useNavigate }          from "react-router-dom";

import { getCookie }            from "../../util/cookies";
import { parseJwt }             from "../../util/jwt";

import { getUserInfo }          from "../../services/user";

import totalspent               from "../../images/totalspent.webp";
import reward                   from "../../images/reward.webp";
import checkout                 from "../../images/checkout.webp";
import sadFace                  from "../../images/sad.webp";

import OrderPreview             from "./OrderPreview";

export default function MemberDashboard()
{
    const navigate = useNavigate();

    const [user, setUser] = useState({ invoices: [] });

    const [totalSpent, setTotalSpent] = useState(0.0);

    useEffect(() => {
        const UTOK = getCookie("UTOK");

        if (!UTOK) {
            navigate("/login");
            return;
        }

        const payload = parseJwt(UTOK);

        if (payload.authorities.includes("ADMIN")) {
            window.location.href = "https://dashboard.altsgreat.com";
            return;
        }

        const fetchUserData = async () => {
            const response = await getUserInfo();

            if (response.status !== 200) return;

            const tempUser = response.data;
            tempUser.invoices.sort((b, a) => new Date(a.invoiceFulfilled) - new Date(b.invoiceFulfilled));

            let tempTotalSpent = 0;
            tempUser.invoices.forEach(invoice => {
                tempTotalSpent += invoice.invoicePrice;
            });

            setTotalSpent(tempTotalSpent / 100);
            setUser(tempUser);
        };

        fetchUserData();
    }, []);

    return (
        <div className="member-dashboard-page">
            <div className="member-dashboard-cont">
                <div className="m-dashboard-info-cont m-dashboard-item">
                    <h3 className="m-dashboard-item-header">Account Info</h3>
                    <div className="m-dashboard-account-info-cont">
                        <div className="m-d-a-i-item">
                            <div className="m-d-a-i-item-img-cont total-spent-img-cont">
                                <img className="m-d-a-i-item-img" src={totalspent} alt="Total Spent" />
                            </div>
                            <div className="m-d-a-i-item-info">
                                <div className="m-d-a-i-item-header">Amount Spent</div>
                                <div className="m-d-a-i-item-contents">
                                    ${totalSpent.toFixed(2)}
                                    <div className="hover-box">
                                        <i className="fa fa-question-circle m-d-a-question" aria-hidden="true" />
                                        <span className="tooltip">The total amount spent</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="m-d-a-i-item">
                            <div className="m-d-a-i-item-img-cont reward-img-cont">
                                <img className="m-d-a-i-item-img" src={reward} alt="Store Credit" />
                            </div>
                            <div className="m-d-a-i-item-info">
                                <div className="m-d-a-i-item-header">Store Credit</div>
                                <div className="m-d-a-i-item-contents">
                                    ${(user.credits / 100).toFixed(2)}
                                    <div className="hover-box">
                                        <i className="fa fa-question-circle m-d-a-question" aria-hidden="true" />
                                        <div className="tooltip">Can be applied to any purchase. Must have at least $5.00 to use.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="m-d-a-i-item">
                            <div className="m-d-a-i-item-img-cont total-orders">
                                <img className="m-d-a-i-item-img" src={checkout} alt="Total Orders" />
                            </div>
                            <div className="m-d-a-i-item-info">
                                <div className="m-d-a-i-item-header">Total Orders</div>
                                <div className="m-d-a-i-item-contents">{user.invoices.length}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="m-dashboard-orders-cont m-dashboard-item">
                    <h3 className="m-dashboard-item-header">Your Orders</h3>
                    <div className="m-dashboard-orders">
                        {user.invoices.length > 0 ? (
                            user.invoices.map(order => <OrderPreview key={order.id} order={order} />)
                        ) : (
                            <div className="m-dashboard-no-orders-cont">
                                <img className="m-dashboard-no-orders-img" src={sadFace} alt="No Orders" />
                                <div className="m-dashboard-no-orders-msg">
                                    No orders, browse products <a className="m-dashboard-browse-products-link" href="/">here</a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}