import { Link }                 from "react-router-dom";

import { useProducts }          from "../../context/ProductsContext";
import { useCurrency }          from "../../context/CurrencyContext";
import { AppConstants }         from "../../constants/AppConstants";

import Product                  from "./Product";

import skyblock                 from "../../images/skyblockcoins.webp";
import crown                  from "../../images/crown.png";

export default function ProductSection() {
    const { currency }  = useCurrency();
    const { products }  = useProducts();

    const handleScroll = (sectionId) => {
        const element = document.getElementById(sectionId);

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="product-section">
            <div className="product-section-category-cont">
                    <div className="ps-nav-bar-item" onClick={() => handleScroll("best-sellers")}>Best Sellers</div>
                    <div className="ps-nav-bar-item" onClick={() => handleScroll("hypixel-section")}>Hypixel</div>
                    <div className="ps-nav-bar-item" onClick={() => handleScroll("temporary-alts-section")}>Temporary Alts</div>
                    <div className="ps-nav-bar-item" onClick={() => handleScroll("exclusive-section")}>Exclusive</div>
                    <div className="ps-nav-bar-item" onClick={() => handleScroll("capes-section")}>Capes</div>
                    <div className="ps-nav-bar-item" onClick={() => handleScroll("skyblock-section")}>Skyblock</div>
                </div>
            <div className="product-section-row">
                <div className="product-section-contents">
                    <h2 id="best-sellers" className="section-header">Minecraft Best Sellers</h2>
                    <div className="store-grid-container">
                        {products?.get("banned-fa") && <Product product={products.get("banned-fa")} />}
                        {products?.get("unbanned-fa") && <Product product={products.get("unbanned-fa")} />}
                        {products?.get("fresh-fa") && <Product product={products.get("fresh-fa")} />}
                        {products?.get("xbox-gamepass-accounts") && <Product product={products.get("xbox-gamepass-accounts")} />}
                    </div>
                    <div className="store-grid-container">
                        {products?.get("no-username-fa") && <Product product={products.get("no-username-fa")} />}
                        <Link className="stats-finder-item" to={`/special-accounts`}>
                            <div className="stats-finder-header">
                                <div className="stats-finder-title">Special Accounts</div>
                            </div>
                            <div className="stats-img-holder">
                                <img src={crown} alt="crown" width={250} height={150} />
                            </div>
                            <div className="stats-finder-info">
                                <div className="stats-mini-details">
                                    <h3 className="stats-finder-desc-header">What is this?</h3>
                                    <div className="stats-finder-description">
                                        Check for special accounts. Ex. Multi-cape, bedwars, etc.
                                    </div>
                                    <button className="stats-finder-search">View Accounts</button>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="product-section-row">
                <div className="product-section-contents">
                    <h2 id="hypixel-section" className="section-header hypixel-alts">Hypixel Full Access</h2>
                    <div className="store-grid-container">
                        {products?.get("mvp+-fa") && <Product product={products.get("mvp+-fa")} />}
                        {products?.get("mvp+-fa-100") && <Product product={products.get("mvp+-fa-100")} />}
                        {products?.get("mvp+-fa-250") && <Product product={products.get("mvp+-fa-250")} />}
                        {products?.get("lvl-mvp-fa") && <Product product={products.get("lvl-mvp-fa")} />}
                    </div>
                    <div className="store-grid-container">
                        {products?.get("level-fa") && <Product product={products.get("level-fa")} />}
                        {products?.get("lvl-vip-fa") && <Product product={products.get("lvl-vip-fa")} />}
                        {products?.get("lvl-vip+-fa") && <Product product={products.get("lvl-vip+-fa")} />}
                    </div>
                </div>
            </div>

            <div className="product-section-row">
                <div className="product-section-contents">
                    <h2 id="skyblock-section" className="section-header">Skyblock</h2>
                    <div className="store-grid-container">
                        {products?.get("skyblock-fa") && <Product product={products.get("skyblock-fa")} />}
                        {products?.get("skyblock-50-fa") && <Product product={products.get("skyblock-50-fa")} />}
                        <Link className="grid-item" to={`${AppConstants.WEB_URL}/skyblock`}>
                            <div className="product-img-holder">
                                <img className="product-thumbnail-img" src={skyblock} alt="Hypixel Skyblock Coins" width={250} height={150} />
                            </div>
                            <div className="grid-item-info">
                                <div className="grid-item-header">
                                    <div className="g-i-h-title">Hypixel Skyblock Coins</div>
                                </div>
                                <div className="product-mini-details">
                                    <div className="product-price">
                                        {currency.symbol}
                                        {(0.045 * currency.rate).toFixed(3)}
                                    </div>
                                    <div className="product-price-real">{currency.symbol}{0.07}</div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="product-section-row">
                <div className="product-section-contents">
                    <h2 id="capes-section" className="section-header">Capes</h2>
                    <div className="store-grid-container">
                        {products?.get("migrator-cape-fa") && <Product product={products.get("migrator-cape-fa")} />}
                        {products?.get("vanilla-cape-fa") && <Product product={products.get("vanilla-cape-fa")} />}
                        {products?.get("cherry-blossom-fa") && <Product product={products.get("cherry-blossom-fa")} />}
                        {products?.get("mojang-office-fa") && <Product product={products.get("mojang-office-fa")} />}
                        {products?.get("mcc-cape-fa") && <Product product={products.get("mcc-cape-fa")} />}
                    </div>
                    <div className="store-grid-container">
                        {products?.get("15th-anniversary-cape-fa") && <Product product={products.get("15th-anniversary-cape-fa")} />}
                        {products?.get("purple-heart-cape-fa") && <Product product={products.get("purple-heart-cape-fa")} />}
                        {products?.get("followers-cape-fa") && <Product product={products.get("followers-cape-fa")} />}
                    </div>
                </div>
            </div>
            <div className="product-section-row">
                <div className="product-section-contents">
                    <h2 id="exclusive-section" className="section-header">Exlusive Accounts</h2>
                    <div className="store-grid-container">
                        {products?.get("prename-fa") && <Product product={products.get("prename-fa")} />}
                        {products?.get("beta-creation-fa") && <Product product={products.get("beta-creation-fa")} />}
                        {products?.get("alpha-creation-fa") && <Product product={products.get("alpha-creation-fa")} />}
                    </div>
                </div>
            </div>
            <div className="product-section-row">
                <div className="product-section-contents">
                    <h2 id="temporary-alts-section" className="section-header">Temporary Alts</h2>
                    <div className="store-grid-container">
                        {products?.get("xbox-gamepass-accounts") && <Product product={products.get("xbox-gamepass-accounts")} />}
                    </div>
                </div>
            </div>
        </div>
    );
}