import { createContext, useContext, useState, useEffect } from "react";
import { ShopProducts } from "../constants/AccountsInfo";
import { getProducts } from "../services/product";

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
    const [products, setProducts] = useState(ShopProducts);
    const [loading, setLoading] = useState(true);

    useEffect(() =>
    {
        const fetchProducts = async () => {
            try {
                const retrievedProducts = await getProducts("regular");
                const productsMap = new Map();

                retrievedProducts.forEach(product => {
                    product.imageUrl = ShopProducts.get(product.productNameId)?.imageUrl;
                    productsMap.set(product.productNameId, product);
                });

                setProducts(productsMap);
            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    return (
        <ProductContext.Provider value={{ products, loading }}>
            {children}
        </ProductContext.Provider>
    );
};

export const useProducts = () => useContext(ProductContext);