import { useEffect, useState }          from "react";

import { copyToClipboard }              from "../../util/clipboard";
import { convertToFormattedDateTime }   from "../../util/helperFunctions";

import { getInvoiceById }               from "../../services/invoice";


import { useAlert }                     from "../../context/AlertContext";

import Securer                          from "./Securer";
import FullAccessSerial                 from "./FullAccessSerial";
import { AppConstants }                 from "../../constants/AppConstants";

export default function OrderPage() {
    let invoiceId = window.location.pathname.split("/")[2];
    
    if(!invoiceId)
    {
        const urlParams = new URLSearchParams(window.location.search);
        const orderId = urlParams.get('id');
        invoiceId = orderId;
    }

    const { addAlert } = useAlert();

    const [activeSerial, setActiveSerial] = useState("");
    const [serials, setSerials] = useState("");
    const [securerOpen, setSecurerOpen] = useState(false);

    const [instructions, setInstructions] = useState(
        'There are no specific instructions for this product. If you are unsure how to use it then join the discord and create a ticket for "Issue with Order".'
    );

    const [order, setOrder] = useState({
        product: {},
    });

    useEffect(() => {
        const fetchOrder = async () => {
            const response = await getInvoiceById(invoiceId);

            switch (response.status) {
                case 401:
                    return setSerials("Open link from your email. If issue persists, join the discord server and create a ticket.");
                case 500:
                    return setSerials("Internal Server Error. This is an issue on our end. Join the discord and create a ticket.");
                default:
                    const order = response.data;
                    setOrder(order);

                    let tempSerials = "";

                    switch (order.invoiceStatus) {
                        case "Not Paid":
                            return setSerials("Order has not been paid for. If you paid, refresh the page after a minute. If that doesn't work then join the Discord server and create a ticket.");
                        case "Revoked":
                        case "Restricted":
                            return setSerials("Order marked as fraud or revoked. Join the discord server and create a ticket to resolve this issue.");
                        case "Fulfilled":
                            return setSerials("Order has already been fulfilled");
                        case "Paid":
                            const serials = order.serials;
                            const product = order.product;
                            const productNameId = product.productNameId;

                            if (productNameId === "skyblock-coins") {
                                tempSerials =
                                    'This product needs to be manually delivered. Join the discord and create a ticket for "Issue with Order" to claim.';
                            } else if (serials.length < 1) {
                                tempSerials =
                                    "Seems like there are no more accounts in stock. This can happen if two people purchased the last account in stock at the same time. Please create a ticket for 'Issue With Order' in the discord server.";
                            } else {
                                if (productNameId.includes("-fa") || (productNameId.includes("-account") && !productNameId.includes("-accounts"))) {
                                    tempSerials = serials.map((serial, index) => (
                                        <FullAccessSerial key={index} openSecurer={setSecurerOpen} serial={serial} setActiveSerial={setActiveSerial} />
                                    ));
                                    setInstructions(
                                        'Click "Open Instructions" to view how to claim your account. For any issues click "Contact Support" and we will try to fix your issue as soon as possible.'
                                    );
                                } else {
                                    if (productNameId === "java-bedrock-key")
                                        setInstructions(
                                            "Go to https://redeem.microsoft.com -> Login with a Microsoft account that does not own Minecraft -> Enter the XXXXX-XXXXX-XXXXX-XXXXX-XXXXX code you received -> Click 'Next' -> Now you own a copy of Minecraft."
                                        );
                                    if (productNameId === "xbox-gamepass-accounts")
                                        setInstructions(
                                            "Go to https://minecraft.net -> Click 'Profile Name' -> Set the username of the account before playing on any clients"
                                        );
                                    let counter = 1;
                                    tempSerials = serials.map((serial, index) => (
                                        <div key={index}>
                                            <b>{counter++}:</b> {serial.serialId}
                                        </div>
                                    ));
                                }
                                setSerials(tempSerials);
                            }

                            break;
                        default:
                            return setSerials("Internal Server Error. This is an issue on our end. Join the discord and create a ticket.");
                    }
            }
        };

        fetchOrder();
    }, []);

    return (
        <div className="i-p-page">
            <div className="i-p-cont">
                <div className="i-p-order-details">
                    <div className="i-p-d-p-h">
                        <div className="i-p-o-d-order-id-cont">
                            <div className="i-p-o-d-order-id-title">Order ID:</div>
                            <div
                                className="i-p-o-d-h-id"
                                onClick={(e) => {
                                    copyToClipboard(e.target.innerText, addAlert);
                                }}
                            >
                                {order.invoiceId}
                            </div>
                        </div>
                        <div id="order-status" className={
                            order.invoiceStatus === "Not Paid" && "unpaid" ||
                            order.invoiceStatus === "Restricted" && "restricted" ||
                            order.invoiceStatus === "Revoked" && "restricted" ||
                            order.invoiceStatus === "Fulfilled" && "restricted" ||
                            order.invoiceStatus === "Paid" && "paid"}>{order.invoiceStatus}</div>
                    </div>
                    <div className="i-p-details">
                        <div className="i-p-o-d-cont">
                            <div className="i-p-o-d-serials">
                                <div>
                                    <div className="fasm-body">
                                        <div>
                                            <div className="fasm-note-title">INSTRUCTIONS</div>
                                            <div className="fasm-note">{instructions}</div>
                                        </div>
                                    </div>
                                </div>
                                {serials}
                            </div>
                        </div>
                        <div className="i-p-info">
                            <div className="i-p-o-i-cont">
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">Product</h4>
                                    <h4 className="i-p-i-c">{order.product.title}</h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">Amount</h4>
                                    <h4 className="i-p-i-c">${(order.invoicePrice / 100).toFixed(2)}</h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">Email</h4>
                                    <h4 className="i-p-i-c">{order.email}</h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">Payment Method</h4>
                                    <h4 className="i-p-i-c">{order.paymentMethod}</h4>
                                </div>
                                <div className="i-p-i-item">
                                    <h4 className="i-p-i-h">Order Created</h4>
                                    <h4 className="i-p-i-c">{convertToFormattedDateTime(order.invoiceCreated)}</h4>
                                </div>
                                <div className="i-p-o-d-actions">
                                    <a href={AppConstants.DISCORD_REGULAR_INVITE} target="_blank" rel="noreferrer">
                                        <button className="i-p-o-d-action action-discord">
                                            <i className="fab fa-discord" /> Contact Support
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Securer isOpen={securerOpen} setOpen={setSecurerOpen} activeSerial={activeSerial} />
        </div>
    );
}