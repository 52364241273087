/**
 * Parses a JWT token into the payload
 * 
 * @param {*} token The JWT Token
 * @returns The payload of the token
 */
export const parseJwt = (token) =>
{
    const tokenParts = token.split(".");
    const payload = JSON.parse(atob(tokenParts[1]));

    return payload;
}

export const isTokenExpired = (token) => 
{
    try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        const expiry = payload.exp;
        const now = Math.floor(Date.now() / 1000);
        return expiry < now;
    } catch (error) {
        console.error("Invalid token", error);
        return true;
    }
}