import { Link }         from "react-router-dom";
import { ShopInfo }     from "../../constants/AppConstants";


export default function Footer() {
    return (
        <div className="footer-box">
            <div className="footer-cont">
                <div className="footer-items">
                    <div className="footer-store-info">
                        <div className="footer-header">
                            <img className="footer-logo" src="/images/logo.webp" alt="AltsGreat Logo" width={150} height={50} />
                        </div>
                        <div className="footer-msg">
                            <h3 className="footer-msg-header">Fueled by {ShopInfo.CompanyName}</h3>
                            <p className="footer-msg-p">{ShopInfo.CompanyName} is the leader in Minecraft Alt technology, offering top-quality accounts known for their reliability and excellence.</p>
                        </div>
                    </div>
                    <ul className="footer-sitemap">
                        <li className="footer-about-us">
                            <h3 className="footer-msg-header">About Us</h3>
                            <Link className="footer-link" to="/support/aboutus/is-alts-great-legit-and-safe-to-buy-from">Why Us?</Link>
                            <Link className="footer-link" to="/support/popular-questions">Support</Link>
                            <Link className="footer-link" to="/support/popular-questions">Frequent Questions</Link>
                        </li>
                        <li className="footer-legal">
                            <h3 className="footer-msg-header">Legal</h3>
                            <Link className="footer-link" to="/terms-of-service">Terms of Service</Link>
                            <Link className="footer-link" to="/privacy">Privacy Policy</Link>
                            <Link className="footer-link" to="/refund-policy">Return Policy</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer-company-details">
                <p>© 2024 Altsgreat.com. All Rights Reserved</p>
            </div>
        </div>
    );
}