import React from "react";
import { useNavigate }  from "react-router-dom";

// Importing images
import cherry           from "../../images/cherry.webp";
import eyeblossom       from "../../images/eyeblossom.webp";
import creeper          from "../../images/creeper.webp";
import mcc              from "../../images/mcc.webp";
import migrator         from "../../images/migrator.webp";
import tiktok           from "../../images/tiktok.webp";
import twitch           from "../../images/twitch.webp";
import vanilla          from "../../images/vanilla.webp";
import mce              from "../../images/mce.webp";
import home             from "../../images/home.webp";
import menace           from "../../images/menace.webp";

export default function AccountPreview({ product }) {
    const navigate = useNavigate();
    const account = product.account;

    const goToCheckout = () => {
        navigate(`/order-page/${product.productNameId}?quantity=1`);
    };

    return (
        <div className="sf-a-preview-item" onClick={goToCheckout}>
            <div className="sf-a-p-i-header">
                <div className="sf-a-p-i-title-cont">
                    <img
                        className="account-avatar"
                        src={`https://crafatar.com/avatars/${account.uuid}?overlay`}
                        alt={`${account.username} character head`}
                        width={50}
                        height={50}
                    />
                    <h4 className="sf-a-p-i-ign">{account.username}</h4>
                </div>
                <div className="sf-a-p-i-price">${product.price.toFixed(2)}</div>
            </div>
            <div className="sf-a-p-i-overview">
                <div className="sf-a-p-i-overview-row">
                    <div className="sf-a-p-i-overview-details">
                        <h4 className="sf-a-p-i-overview-h">Hypixel Level</h4>
                        <p className="sf-a-p-i-overview-p">{account.hypixelLevel}</p>
                    </div>
                    <div className="sf-a-p-i-overview-details">
                        <h4 className="sf-a-p-i-overview-h">Hypixel Rank</h4>
                        <p className={`sf-a-p-i-overview-p ${account.hypixelRank.toLowerCase().split("+")[0]}`}>
                            {account.hypixelRank}
                        </p>
                    </div>
                </div>
                <div className="sf-a-p-i-overview-row">
                    <div className="sf-a-p-i-overview-details">
                        <h4 className="sf-a-p-i-overview-h">Bedwars Stars</h4>
                        <p className="sf-a-p-i-overview-p">{account.bedwarsLevel}⭐</p>
                    </div>
                    <div className="sf-a-p-i-overview-details">
                        <h4 className="sf-a-p-i-overview-h">Final Kills</h4>
                        <p className="sf-a-p-i-overview-p">{account.bedwarsFinalKills}⚔️</p>
                    </div>
                    <div className="sf-a-p-i-overview-details">
                        <h4 className="sf-a-p-i-overview-h">FKDR</h4>
                        <p className="sf-a-p-i-overview-p">{account.bedwarsFKDR}</p>
                    </div>
                </div>
                <div className="sf-a-p-i-overview-capes-cont">
                    <h4 className="sf-a-p-i-overview-h">Capes</h4>
                    <div className="sf-a-p-capes-container">
                        {account.capes.includes("Minecraft Experience") && (
                            <img src={mce} className="sf-a-p-cape-img" alt="Minecraft Experience Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("MCC 15th Year") && (
                            <img src={mcc} className="sf-a-p-cape-img" alt="MCC 15th Year Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("Mojang Office") && (
                            <img src={eyeblossom} className="sf-a-p-cape-img" alt="Mojang Office Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("Cherry Blossom") && (
                            <img src={cherry} className="sf-a-p-cape-img" alt="Cherry Blossom Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("Purple Heart") && (
                            <img src={twitch} className="sf-a-p-cape-img" alt="Purple Heart Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("Vanilla") && (
                            <img src={vanilla} className="sf-a-p-cape-img" alt="Vanilla Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("15th Anniversary") && (
                            <img src={creeper} className="sf-a-p-cape-img" alt="15th Anniversary Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("Migrator") && (
                            <img src={migrator} className="sf-a-p-cape-img" alt="Migrator Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("Follower's") && (
                            <img src={tiktok} className="sf-a-p-cape-img" alt="Follower's Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("Home") && (
                            <img src={home} className="sf-a-p-cape-img" alt="Home Cape" width={32} height={32} />
                        )}
                        {account.capes.includes("Menace") && (
                            <img src={menace} className="sf-a-p-cape-img" alt="Menace Cape" width={32} height={32} />
                        )}
                    </div>
                </div>
                <div className="sf-a-p-i-overview-capes-cont">
                    <h4 className="sf-a-p-i-overview-h">Extras:</h4>
                    <ul className="sf-a-p-i-overview-ul">
                        {account.tags.map((tag, index) => (
                            <li key={index}>{tag}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}