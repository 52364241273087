import { Link }                         from "react-router-dom";

import { useAlert }                     from "../../context/AlertContext";

import { sendVerificationLink }         from "../../services/register";

import mail                             from "../../images/mail.webp";

export default function RegistrationSuccess({ email }) 
{
    const { addAlert }                  = useAlert();

    const handleResendVerification = async () =>
    {
        try
        {
            const response = await sendVerificationLink();

            switch (response?.data?.message)
            {
                case "SUCCESS":
                    return addAlert("Verification email sent! Check your email", "success", 4000);
                case "ALREADY_VERIFIED":
                    return addAlert("Email is already verified", "warning", 4000);
                default:
                    return addAlert("Couldn't send verification email. Contact support.", "warning", 4000);
            }
        } catch (err) {
            console.log(err);
            return addAlert("Couldn't send verification email. Contact support.", "warning", 4000);
        }
    };

    return (
        <div className="verify-cont">
            <div className="verify-header-cont">
                <img src={mail} className="verify-img" alt="Mail"/>
                <h3 className="verify-header">Verify your email address</h3>
                <p className="verify-message">You're almost done! We have sent an email to</p>
                <p className="verify-message"><strong>{email}</strong></p>
            </div>
            <div className="verify-instructions">
                <p className="verify-i-item">Click on the link in the email to complete verification.</p>
                <p className="verify-i-item">If you don't see it then <strong>check Spam/Junk.</strong></p>
            </div>
            <button className="verify-resend-btn" onClick={handleResendVerification}>Resend Verification Email</button>
            <div className="verify-footer">
                Need Help? <Link to="/support" className="verify-contact">Contact Support</Link>
            </div>
        </div>
    );
}