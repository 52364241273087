import { useState, useEffect }  from 'react';
import { useNavigate }          from "react-router-dom";

import { useAlert }             from "../../context/AlertContext";

import { getProductByNameId }   from '../../services/product';

import { ShopProducts }         from '../../constants/AccountsInfo';

import { calculatePrice }       from '../../util/product';

import { useCurrency }          from '../../context/CurrencyContext';

import ProductDetails           from './ProductDetails';
import WarrantyDetails from './WarrantyDetails';

export default function ProductPage()
{
    const navigate                                  = useNavigate();

    const { addAlert }                              = useAlert();
    const { currency }                              = useCurrency();

    const productNameId                             = window.location.pathname.split("/")[2];    

    const [orderAmount, setOrderAmount]             = useState(1);

    const [product, setProduct]                     = useState({});

    const [productDuration, setProductDuration]     = useState("Lifetime");
    const [warrantyDuration, setWarrantyDuration]   = useState("Lifetime");
    const [notes, setNotes]                         = useState([]);

    useEffect(() => 
    {
        const updateMetaTag = (property, content) =>
        {
            let metaTag = document.querySelector(`meta[${property}]`);
            if (metaTag) {
              metaTag.setAttribute("content", content);
            } else {
              metaTag = document.createElement("meta");
              metaTag.setAttribute(property, property.includes("og:") ? "property" : "name");
              metaTag.setAttribute("content", content);
              document.head.appendChild(metaTag);
            }
        };
      
        if (productNameId.includes("-fa")) {
            setNotes(["Permanent", "Instant Delivery", "Lifetime Warranty", "Full Access"]);
        } else if (productNameId === "java-bedrock-key") {
            setNotes(["Permanent", "Instant Delivery", "Lifetime Warranty", "Redeem Key"]);
        } else if (productNameId === "skyblock-coins") {
            setWarrantyDuration("No Warranty");
            setNotes(["Manual Delivery"]);
        } else if (productNameId === "xbox-gamepass-accounts") {
            setWarrantyDuration("Seven Days");
            setProductDuration("7 - 14 Days");
            setNotes(["Instant Delivery", "7-Day Warranty", "Unbanned Everywhere"]);
        }
        
        const fetchProduct = async () => {
            try {
                const response = await getProductByNameId(productNameId);
                if (response)
                {
                    const retrievedProduct = response.data;
                    retrievedProduct.imageUrl = ShopProducts.get(productNameId)?.imageUrl;
                
                    setProduct(retrievedProduct);
                    setOrderAmount(retrievedProduct.minimum);

                    document.title = `${retrievedProduct.longTitle || "Cheap Minecraft Microsoft Alts"}`;
                    updateMetaTag("property='og:title'", retrievedProduct.longTitle || "Cheap Minecraft Microsoft Alts");
                    updateMetaTag("property='og:site_name'", retrievedProduct.longTitle || "Cheap Minecraft Microsoft Alts");
                    updateMetaTag("name='description'", retrievedProduct.enhancedDescription || "Buy cheap Minecraft Microsoft accounts.");
                    updateMetaTag("property='og:description'", retrievedProduct.enhancedDescription || "Buy cheap Minecraft Microsoft accounts.");
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchProduct();
    }, []);

    const openCheckout = () => {
        if (orderAmount < product.minimum) {
            return addAlert(`Minimum order amount is ${product.minimum}`, "warning", 3000);
        }
        if (orderAmount > product.quantity) {
            return addAlert(`Not enough stock to fulfill order`, "warning", 3000);
        }
        navigate(`/order-page/${productNameId}?quantity=${orderAmount}`);
    };

    const changeQuantity = (e) => {
        let oAmount = parseInt(orderAmount);
        if (e.target.id === "i-quantity" && product.quantity > oAmount) {
            oAmount += 1;
        } else if (e.target.id === "d-quantity" && product.minimum < oAmount) {
            oAmount -= 1;
        }
        setOrderAmount(oAmount);
    };

    const goBack = () => {
        navigate("/");
    };

    const onChange = (e) => {
        setOrderAmount(e.target.value)
    }

    return (
        <div>
            <div className='product-page-cont'>
                <div className='product-page-top-segment'>
                    <div className='product-page-go-back-cont' onClick={goBack}>
                        <div className="product-page-go-back">
                            <svg className="checkout-header-go-back" xmlns="http://www.w3.org/2000/svg" viewBox="50 0 450 450" width="20px" height="20px" fill="black">
                                <polygon points="352,128.4 319.7,96 160,256 319.7,416 352,383.6 224.7,256" />
                            </svg>
                        </div>
                        <div className='product-page-go-back-text'>BACK TO ALL PRODUCTS</div>
                    </div>

                    <div className="p-p-main ">
                        <div className='product-img-cont desktop-item'>
                            <img src={product.imageUrl} className="product-image" alt={product.title} />
                        </div>

                        <div className="p-p-m-info">
                            <div className="p-p-m-i-cont">
                                <div className='p-p-m-header-cont'>
                                    <h1 className="p-p-m-header">{product.longTitle}</h1>
                                    <img src={product.imageUrl} className='product-image-thumbnail mobile-item' alt={product.title}/>
                                </div>
                                <ol className="p-p-m-bonus">
                                    {
                                        notes.map(note => <li>{note}</li>)
                                    }
                                </ol>
                                <p className="p-p-m-description">{product.enhancedDescription}</p>
                            </div>

                            <div className='s-c-p-prices desktop-item'>
                                <div className='s-c-p-price-cont'>
                                    <div className='s-c-p-price-display'>
                                        <span className='s-c-p-main-price'>
                                            {currency.symbol}{(calculatePrice(product, orderAmount) * currency.rate).toFixed(2)}
                                        </span>
                                        <span className='s-c-p-dashed-price'>
                                            {currency.symbol}{((product.price * currency.rate) * orderAmount * 1.35).toFixed(2)}
                                        </span>
                                    </div>
                                    <div className='s-c-p-percent-off'>
                                        - 35%
                                    </div>
                                </div>
                            </div>
                            <div className='s-c-p-discounts'>
                                <div className='s-c-p-discount-list'>
                                    {product.discounts?.map(discount => {
                                        return <div className='s-c-p-discount-detail'>Buy <b className='s-c-p-discount-enchance'>{discount.minimumQuantity}</b> or more and get <b className='s-c-p-discount-enchance'>{discount.percentOff}%</b> off your order!</div>
                                    })}
                                </div>
                            </div>
                            <div className="button-div desktop-item">
                                <div className="s-c-p-quantity-setter">
                                    <button type="button" className="s-c-p-q-b" id="d-quantity" onClick={changeQuantity}>-</button>
                                    <input className="s-c-p-quantity-i" placeholder="1" onChange={onChange} value={orderAmount} type="number" />
                                    <button type="button" className="s-c-p-q-b" id="i-quantity" onClick={changeQuantity}>+</button>
                                </div>
                                <button className="purchase " onClick={openCheckout}>Buy Now</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-p-extra">
                    <div className="p-p-product-details-header">Product Details</div>
                    <div className='p-p-info-mini'>
                        <ProductDetails productDuration={productDuration}/>
                    </div>
                    <div className="p-p-product-details-header">Warranty Details</div>
                    <div className='p-p-info-mini'>
                        <WarrantyDetails warrantyDuration={warrantyDuration}/>
                    </div>
                </div>
            </div>

            <div className='product-page-mobile-purchase mobile-item'>
                <div className="product-page-mobile-purchase-actions">
                    <div className="s-c-p-quantity-setter">
                        <button type="button" className="s-c-p-q-b" id="d-quantity" onClick={changeQuantity}>-</button>
                        <input className="s-c-p-quantity-i" placeholder="1" onChange={onChange} value={orderAmount} type="number" />
                        <button type="button" className="s-c-p-q-b" id="i-quantity" onClick={changeQuantity}>+</button>
                    </div>
                    <div className='product-page-mobile-purchase-price'>
                        <div className='s-c-p-price-cont'>
                            <div className='s-c-p-price-display'>
                                <span className='s-c-p-main-price'>
                                    {currency.symbol}{(calculatePrice(product, orderAmount) * currency.rate).toFixed(2)}
                                </span>
                                <span className='s-c-p-dashed-price'>
                                    {currency.symbol}{((product.price * currency.rate) * orderAmount * 1.35).toFixed(2)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <button className="purchase blue" onClick={openCheckout}>Buy Now</button>
                </div>
                <div className='power-by'>Powered by GreatAlts</div>
            </div>
        </div>
    );
}