import { ShopInfo } from "../../constants/AppConstants";

export default function RefundPolicy()
{
    return (
        <div className="tos">
            <h1 className="tos-header">
                Refund Policy
            </h1>
            <p className="tos-description">
            <i>This refund policy page outlines the criteria for eligibility to return a product in exchange for a refund and answers frequently asked questions about our return policy.</i>
            </p>
            <h2 className="tos-section-header">Refunds for digital products.</h2>
            <p className="terms">
            While we strive for perfection and our products consistently function smoothly, there may be rare occasions where an account is uploaded incorrectly. In this rare case, if a replacement cannot be provided or our support cannot fix the issue, a full refund will be provided. Simply contact our support to resolve any issues.
            </p>
            <h2 className="tos-section-header">Product Delivery and Activation</h2>
            <p className="terms">
            Upon successful payment, your purchased digital product will be delivered to you electronically. We highly recommend that you verify the product details before completing your purchase, as no changes or cancellations can be made once the digital product has been delivered.
            </p>
            <h2 className="tos-section-header">Product Keys and Account Information</h2>
            <p className="terms">
            It is your responsibility to securely store and manage any product keys, account information, or activation codes provided after your purchase. We cannot reissue or replace these items if they are lost, stolen, or used without your permission.
            </p>
            <h2 className="tos-section-header">Technical Issues</h2>
            <p className="terms">
            If you experience technical issues with your digital product, please contact our support team within 24 hours of delivery. We will do our best to assist you in resolving any issues related to the activation or functionality of the product. However, this does not guarantee a refund or exchange.
            </p>
            <h2 className="tos-section-header">Fraud and Abuse</h2>
            <p className="terms">
            We reserve the right to refuse refunds or returns in cases of suspected fraud, abuse, or other violations of our terms and conditions. Any fraudulent activity may result in the suspension or termination of your account and the cancellation of any associated digital products.
            </p>
            <h2 className="tos-section-header">Refund Processing Time</h2>
            <p className="terms">
            Depending on your payment method, a refund may take up to 7 business days to process.
            </p>
            <h2 className="tos-section-header">Return Policy Changes</h2>
            <p className="terms">
            Although most changes are likely to be minor, {ShopInfo.CompanyName} may change its Return Policy from time to time, and in {ShopInfo.CompanyName}'s sole discretion. {ShopInfo.CompanyName} encourages visitors to frequently check this page for any changes to its Return Policy. Your continued use of this site after any change in this Return Policy will constitute your acceptance of such change.
            </p>
            <h2 className="tos-section-header">Contact Us</h2>
            <p className="terms">
            If you have any questions or concerns about this return policy, please contact our customer support team.
            </p>
        </div>
    )
}