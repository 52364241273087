import { useEffect, useState }                      from "react";
import { useNavigate, useSearchParams, Link }       from "react-router-dom";

import { deleteCookie, getCookie, setCookie }                  from "../../util/cookies";
import { delay }                                    from "../../util/delay";

import { verifyEmail }                              from "../../services/register";

import errorIcon                                    from "../../images/remove.webp";
import checkIcon                                    from "../../images/check.webp";

export default function VerifyEmailPage()
{
    const [ verificationStatus, setVerificationStatus ] = useState("Verifying");
    const [searchParams]            = useSearchParams();
    const token                     = searchParams.get("token");

    const navigate                  = useNavigate();

    useEffect(() => 
    {
        if(!getCookie("UTOK"))
        {
            const verifyToken = async () =>
            {
                const response = await verifyEmail(token);
                console.log(response?.data?.message)
                switch(response?.data?.message)
                {
                    case "SUCCESS":
                        // Set the new JWT Token in the browser
                        setCookie("UTOK", response.headers.authorization);

                        // Delete RTOK
                        deleteCookie("RTOK");
                        setVerificationStatus("Success");
                        await delay(3000);
                        return navigate("/users");
                    case "INVALID_TOKEN":
                    case "EXPIRED_TOKEN":
                        return setVerificationStatus("Invalid");
                    default:
                        return setVerificationStatus("Failed");

                }
            };
            verifyToken();
        }else{
            return navigate("/users")
        }
    }, []);

    return (
        <div>
            { verificationStatus === "Verifying" && 
            <div className="verify-cont">
                <div className="verify-header-cont">
                    <h3 className="verify-header">Verifying...</h3>
                    <p className="verify-message">Give us a moment while we verify</p>
                </div>
                <div className="verify-footer">
                    Need Help? <Link to="/support" className="verify-contact">Contact Support</Link>
                </div>
            </div>}
            { verificationStatus === "Success" && 
            <div className="verify-cont">
                <div className="verify-header-cont">
                    <img src={checkIcon} className="verify-img" alt="Verification successful" width={80} height={80} />
                    <h3 className="verify-header">Verification Successful</h3>
                    <p className="verify-message">Redirecting to customer dashboard in 3 seconds...</p>
                </div>
                <div className="verify-footer">
                    Need Help? <Link to="/support" className="verify-contact">Contact Support</Link>
                </div>
            </div> }
            { verificationStatus === "Invalid" && 
            <div className="verify-cont">
                <div className="verify-header-cont">
            
                    <h3 className="verify-header">Link Expired</h3>
                    <p className="verify-message">Use the most recent link or request a new one.</p>
                </div>
                <div className="verify-footer">
                    Need Help? <Link to="/support" className="verify-contact">Contact Support</Link>
                </div>
            </div> }
            { verificationStatus === "Failed" && 
            <div className="verify-cont">
                <div className="verify-header-cont">
                    <img src={errorIcon} className="verify-img" alt="Verification error" width={80} height={80} />
                    <h3 className="verify-header">Verification Unsuccessful</h3>
                    <p className="verify-message">Check your email and click on the verification link.</p>
                </div>
                <div className="verify-footer">
                    Need Help? <Link to="/support" className="verify-contact">Contact Support</Link>
                </div>
            </div> }
        </div>
    );
}