/**
 * This function will check if a quantity is qualified for a discount
 * 
 * @param {*} product The product being purchased
 * @param {*} quantity The amount being purchased
 * @returns 
 */
export const calculatePrice = (product, quantity) => 
{
    // Set the price to price * quantity
    let price = product.price * quantity

    const discounts = product.discounts;

    // Check if there are any discounts for the product
    if(discounts && discounts.length > 0)
    {
        // Sort the discounts in descending order
        discounts.sort((a, b) => b.minimumQuantity - a.minimumQuantity);

        // Check which discount quota is matched
        for(const discount of discounts)
        {
            if(quantity >= discount.minimumQuantity)
            {
                price = price * ((100 - discount.percentOff) / 100);
                return price;
            }
        }
    }

    return price;
}