import "../../styles/product.css";

import React                from "react";
import XboxLogo             from "../svg/XboxLogo";
import PlayStationLogo      from "../svg/PlayStationLogo";
import NintendoSwitchLogo   from "../svg/NintendoSwitchLogo";
import AppleLogo            from "../svg/AppleLogo";
import AndroidLogo          from "../svg/AndroidLogo";

export default function WarrantyDetails({warrantyDuration})
{
    const data = [
        {
            Duration: warrantyDuration
        }
    ];

    return (
        <div className="table-container">
            <table className="custom-table">
                <tbody>
                    {Object.keys(data[0]).map((key, index) => (
                        <tr key={index}>
                            <th>{key.replace(/([A-Z])/g, " $1").trim()}</th>
                            {data.map((row, rowIndex) => (
                                key.replace(/([A-Z])/g, ' $1').trim() !== "Supported Devices" ? (
                                    <td key={rowIndex}>{row[key]}</td>
                                ) : (
                                    <td>
                                        <div key={rowIndex} className="supported-devices-cont">
                                            <svg version="1.1" width="22" height="22" viewBox="0 0 512 512" fill="gray">
                                                <g><polygon points="0,80 0,240 224,240 224,52" /></g>
                                                <g><polygon points="256,48 256,240 512,240 512,16" /></g>
                                                <g><polygon points="256,272 256,464 512,496 512,272" /></g>
                                                <g><polygon points="0,272 0,432 224,460 224,272" /></g>
                                            </svg>
                                            <XboxLogo           width="22" height="22" color="gray" />
                                            <PlayStationLogo    width="22" height="22" color="gray" />
                                            <NintendoSwitchLogo width="22" height="22" color="gray" />
                                            <AppleLogo          width="22" height="22" color="gray" />
                                            <AndroidLogo        width="22" height="22" color="gray" />
                                        </div>
                                    </td>
                                )
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="warranty-specs-header">Specifications</div>
            <div className="warranty-specs-cont">
                <h4 className="warranty-specs-cont-header">What is covered by our warranty?</h4>
                <div>• If our product does not match the description we provide. </div>
                <div>• If the provide suddenly stops working properly due to a fault with our product.</div>
                <div>• If the customer loses access to the product due to a fault with our product.</div>
                <div>It is the customer's responsibility to contact us immediately and provide accurate and detailed information about the issue.</div>
                <h4 className="warranty-specs-cont-header">What is not covered by our warranty?</h4>
                <div>• If the customer causes their account to receive a (Server Ban/Mute/Punishment/Suspension/Block/etc.) </div>
                <div>• If the customer forgets or loses their login information. It is the customer's responsibility to safely store their login credentials.</div>
                <div>• Giving out any account information which causes your account to be hacked.</div>
                <div>• Providing false information when contacting our support.</div>
                <div>• Failure to follow instructions provided in your order page which causes an issue to arise.</div>
            </div>
        </div>
    );
};