import { ShopInfo } from "../../../constants/AppConstants";

export default function WhatIsTheRefundPolicy(){
    return (
        <>
            <div className="support-questions-header">What is the Refund Policy?</div>
            <article className="support-questions-solution">
                <section>
                    <h3 className="support-answers-section-header">Refund policy</h3>
                    <p>Our refund policy can be found at <a href={ShopInfo.RefundPolicy}>{ShopInfo.RefundPolicy}</a></p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}