import { createContext, useContext, useEffect, useState }  from "react";
import { getCookie, setCookie }                            from "../util/cookies.js";
import { getCurrencyRate }                      from "../services/currency.js";

export const CurrencyContext = createContext();

export function useCurrency()
{
    return useContext(CurrencyContext);
}

export const CurrencyProvider = ({ children }) => 
{
    const [ currencyMenuClosed, setCurrencyMenuClosed ] = useState(true);
    const [ currency, setCurrency ] = useState(
        {
            symbol: "$",
            currency: "USD",
            rate: 1.0
        });

    useEffect(() => {
        const currency = getCookie("currency");

        if (!currency)
        {
            setCookie("currency", "USD", 30);
        } else {
            changeCurrency(currency);
        }
    }, [])

    const closeCurrencyMenu = () =>
    {
        setCurrencyMenuClosed(!currencyMenuClosed);
    }

    const changeCurrency = async (cur) =>
    {
        try
        {
            const response = await getCurrencyRate(cur);
            const tempCurrency = {
                currency: cur,
                rate: response.message
            };

            tempCurrency.symbol =
                cur === "USD" || cur === "NZD" || cur === "CAD" ? "$" :
                cur === "EUR" ? "€" :
                cur === "JPY" ? "¥" :
                cur === "GBP" ? "£" : "$";

            setCookie("currency", cur, 30);
            setCurrency({ ...tempCurrency });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <CurrencyContext.Provider value={{ currency, changeCurrency, currencyMenuClosed, closeCurrencyMenu }}>
            {children}
        </CurrencyContext.Provider>
    );
};