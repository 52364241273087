import api                          from "./apiConfig";
import { RouteConstants }           from "../constants/AppConstants";
import { getCookie }                from "../util/cookies";

/**
 * Sends a request to the backend to register the data.
 * 
 * @param {*} data The data for the member.
 * @returns The response from the backend.
 */
export const registerMember = async (data) => {
    try 
    {
        const response = await api.post(`${RouteConstants.REGISTER_URL}`, data)
        return response;
    } catch (err) {
        console.log(err)
        return err.response;
    }
}

/**
 * Sends a request to the backend to send a verification link to the amil
 * 
 * @param {*} email The email to verify
 * @returns The status of the request sent
 */
export const sendVerificationLink = async () => {
    try 
    {
        const RTOK = getCookie("RTOK");

        const response = await api.post(`${RouteConstants.REGISTER_URL}/sendverificationlink`, {
            headers: {
                Authorization: RTOK
            }
        })
        return response;
    } catch (err) {
        console.log(err);
        return err.response;
    }
}

/**
 * Send a request to verify an email from the verification link.
 * 
 * @param {*} token The token to authenticate the link.
 * @returns The response from the backend for the the status of the request.
 */
export const verifyEmail = async (token) => {
    try {
        const response = await api.post(`${RouteConstants.REGISTRATION_LINK}/verify?token=${token}`)
        return response;
    } catch (err) {
        console.log(err);
        return err.response;
    }
}