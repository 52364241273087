import { Link } from "react-router-dom";

export default function ProductQuestions() {
    return (
        <>
            <div className="support-questions-cont">
                <div className="support-questions-header">Products</div>
                <div className="support-main-questions-cont">
                    <Link to="/support/products/what-does-full-access-mean" className="support-main-questions-item">
                        What does "Full Access" mean?
                    </Link>
                    <Link to="/support/products/difficulty-deciding-between-products" className="support-main-questions-item">
                        I am having difficulties deciding which product I should purchase.
                    </Link>
                    <Link to="/support/products/difficulty-deciding-between-products" className="support-main-questions-item">
                        What is the difference between Fresh Full Access and Unbanned Full Access?
                    </Link>
                    <Link to="/support/products/do-you-have-accounts-with-specific-stats-on-hypixel" className="support-main-questions-item">
                        Do you have accounts with specific stats on Hypixel? Ex. Skyblock, Bedwars, Skywars, etc.
                    </Link>
                    <Link to="/support/aboutus/how-does-this-work" className="support-main-questions-item">
                        How are these products obtained?
                    </Link>
                    <Link to="/support/products/do-you-sell-minecon-capes" className="support-main-questions-item">
                        Do You Sell Minecon Capes?
                    </Link>
                </div>
            </div>
        </>
    );
}