import { createContext, useContext, useState }  from "react";
import Alerts                                   from "../components/global/Alerts";

const AlertContext = createContext();

export function AlertProvider({ children }) {
    const [alerts, setAlerts] = useState([]);

    const addAlert = (content, type = "success", duration = 3000) =>
    {
        const id = Math.random().toString(36).substr(2, 9);
        setAlerts((prevAlerts) => [...prevAlerts, { id, content, type }]);

        setTimeout(() => {
            setAlerts((prevAlerts) => prevAlerts.filter(alert => alert.id !== id));
        }, duration);
    };

    return (
        <AlertContext.Provider value={{ addAlert }}>
            {children}
            <Alerts alerts={alerts} />
        </AlertContext.Provider>
    );
}

export function useAlert() {
    return useContext(AlertContext);
}