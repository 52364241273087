
export default function MyPaymentFailedOrGotDeclined()
{
    return (
        <>
            <div className="support-questions-header">My payment/transaction failed or got declined. What now?</div>
            <article className="support-questions-solution">
                <section>
                    <h3 className="support-answers-section-header">Bank Declines</h3>
                    <p>The most common reason for payment failures is due to bank declines.</p>
                    <p>First, check if your bank emailed or texted you asking to verify the payment. If they did then follow the verification and try to pay again.</p>
                    <p>Next, call your bank 90% of the time when a payment is declined and you call your bank, your bank will remove the block and instruct you to try again.</p>
                </section>
                <section><h3 className="support-answers-section-header">Fraud Detection</h3>
                <p>The second most common reason for payment failures is fraud detection. This is Stripe believing that your payment is somewhat fraudulent.</p>
                <p>If you are on a VPN/Proxy/TOR Node, then try disconnecting and retrying. Also ensure that all your payment details are correct.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}