import { useEffect, useState }      from "react";
import { useNavigate }              from "react-router-dom";
import { Link }                     from "react-router-dom";
import { useGoogleLogin }           from "@react-oauth/google";

import { useAlert }                 from "../../context/AlertContext";


import { isValidEmail }             from "../../util/isValidEmail";
import { isValidPassword }          from "../../util/isValidPassword";
import { getCookie, setCookie }     from "../../util/cookies";

import { registerMember }           from "../../services/register";
import { getGoogleOAuth }           from "../../services/oauth";

import RegistrationSuccess          from "../register/RegistrationSuccess";

export default function Register()
{
    const { addAlert }                          = useAlert();

    const [email, setEmail]                     = useState("");
    const [password, setPassword]               = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [accepted, setAccepted]               = useState(false);
    const [showVerify, setShowVerify]           = useState(false);

    const navigate = useNavigate();
    
    useEffect(() => {
        // If the UTOK cookie exist, that means there is someone logged on
        if(getCookie("UTOK"))
        {
            // Go to the dashboard
            navigate("/users");
        }
    }, []);

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                // Fetch user details from backend
                const res = await getGoogleOAuth(response.access_token);

                if(res.status !== 201 || !res?.headers?.authorization)
                {
                    return addAlert("Failed to Login", "warning", 3000);
                }

                let token = res.headers.authorization;   
                setCookie("UTOK", token, 30);
    
                navigate(-1);
            } catch (err) {
                console.error("Google Login Failed:", err);
            }
        },
        onError: (error) => console.error("Google Login Failed:", error)
    });

    const onSubmit = async (e) => {
        e.preventDefault();

        // Check if the terms of service is accepted
        if (!accepted)                      return addAlert("You must accept the terms of service", "warning", 3000)
        if (!isValidEmail(email))           return addAlert("Invalid Email", "warning", 3000);
        if (!isValidPassword(password))     return addAlert("Password must contain 1 uppercase, 1 lowercase, 1 digit, and be at least 8 characters long.", "warning", 3000);
        if (password !== confirmPassword)   return addAlert("Passwords do not match", "warning", 3000);

        const registerRequest = await registerMember({ email, password });

        switch (registerRequest?.data?.message) {
            // If the registration was successful
            case "SUCCESSFULLY_REGISTERED":
                // Get the RTOK token from the response
                let rtoken = registerRequest.headers.authorization;
                
                // Set the RTOK token as a cookie
                setCookie("RTOK", rtoken, 30);

                // Show the verification message
                setShowVerify(true);
                break;
            case "INVALID_EMAIL":
                return addAlert("Invalid Email", "warning", 3000);
            case "EMAIL_ALREADY_USED":
                return addAlert("Email already in user", "warning", 3000);
            default:
                return addAlert("Unknown Error, Contact Support", "warning", 3000);
        }
    };

    return (
        <div>
            {!showVerify ? (
                <form className="member-login-cont" onSubmit={onSubmit}>
                    <div className="s-r-header-cont">
                        <h3 className="s-r-header">Create an account</h3>
                        <p className="s-r-h-msg">Join the millions of users and track every order!</p>
                    </div>
                    <div className="s-r-input-cont">
                        <h4 className="s-r-input-header">Enter your email</h4>
                        <input className="s-r-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="s-r-input-cont">
                        <h4 className="s-r-input-header">Create a password</h4>
                        <input className="s-r-input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <div className="s-r-input-cont">
                        <h4 className="s-r-input-header">Confirm your password</h4>
                        <input className="s-r-input" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                    </div>
                    <div className="s-r-agree-cont">
                        <div className="s-r-agree-main">
                            <input className="s-r-checkbox" type="checkbox" checked={accepted} onChange={() => setAccepted(!accepted)} />
                            <span className="s-r-checkbox-msg">
                                By checking, you agree to all <Link to="/help/terms-of-service" className="register-tos-link">terms and services</Link>
                            </span>
                        </div>
                    </div>
                    <input className="s-r-submit-btn" value="Create account" type="submit" />
                    <div className="s-r-or-msg">or</div>
                    <div className="s-r-google-sign-in-cont" onClick={handleGoogleLogin}>
                        <div className="s-r-google-logo-cont">
                            <svg class="s-r-google-logo" viewBox="0 0 256 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                                <path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path>
                                <path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path>
                                <path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path>
                                <path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EA4335"></path>
                            </svg>
                        </div>
                        <div className="s-r-google-msg">Sign up with Google</div>
                    </div>
                    <div className="s-r-footer">
                        Already Registered? <Link to="/login" className="s-r-switch">Sign in</Link> to track your orders!
                    </div>
                </form>
            ) : (
                <RegistrationSuccess email={email} />
            )}
        </div>
    );
}