
export default function HowLongDoesTheWarrantyLastAfterPurchasing()
{
    return (
        <>
            <div className="support-questions-header">How Long Does My Warranty Last After Purchasing?</div>
            <article className="support-questions-solution">
                <section>
                    <h3 className="support-answers-section-header">Warranties</h3>
                    <p>All warranties are listed in the warranty section of product page. If the warranty is not listed, please contact us and ask.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}