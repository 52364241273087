import "./styles/global.css";
import "./styles/navbar.css";
import "./styles/footer.css";
import "./styles/hoverFooter.css";
import "./styles/store.css";
import "./styles/login.css";
import "./styles/memberDashboard.css";
import "./styles/verify.css";
import "./styles/skyblock.css";
import "./styles/product.css";
import "./styles/accountPreview.css";
import "./styles/specialAccounts.css";
import "./styles/support.css";
import "./styles/answers.css";
import "./styles/tos.css";
import "./styles/checkoutpage.css";
import "./styles/orderpage.css";
import "./styles/securer.css";
import "./styles/seo.css";

import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect }                  from "react";

import { getCookie, setCookie }       from "./util/cookies.js";
import { getLocalStorageItem }        from "./util/localstorage.js";

import Home                           from "./components//home/Home.js";
import CheckoutPage                   from "./components/checkout/CheckoutPage.js";
import ProductPage                    from "./components/products/ProductPage.js";
import OrderPage                      from "./components/orderPage/OrderPage.js";
import MemberDashboard                from "./components/users/UserDashboard.js";
import Support                        from "./components/support/index.js";
import TermsOfService                 from "./components/support/TermsOfService.js";
import RefundPolicy                   from "./components/support/RefundPolicy.js";
import Privacy                        from "./components/support/Privacy.js";
import Login                          from "./components/users/Login.js";
import Register                       from "./components/register/Register.js";
import VerifyEmailPage                from "./components/register/VerifyEmail.js";
import ResetPassword                  from "./components/users/ResetPassword.js";
import ForgotPassword                 from "./components/users/ForgotPassword.js";
import SpecialAccounts                from "./components/specialAccounts/SpecialAccounts.js";
import SkyblockCoinsPage              from "./components/products/skyblock.js";
import NotFound                       from "./components/404/NotFound.js";
import Navbar                         from "./components/global/Navbar.js";
import HoverFooter                    from "./components/global/HoverFooter.js";
import Footer                         from "./components/global/Footer.js";

export default function App()
{
  const location = useLocation();

  useEffect(() => {
    let referrer;
    
    try
    {
      // Check who referred the user to the store
      if (getLocalStorageItem("visited")) {
        referrer = "default";
        setCookie("referrer", referrer, 14);
        window.localStorage.removeItem("visited");
      } else if (!getCookie("referrer")) {
        if (window.location.pathname && window.location.pathname.includes("r=")) {
          referrer = window.location.pathname.split("r=")[1];
        } else {
          referrer = "default";
        }
        setCookie("referrer", referrer, 14);
      }
    } catch (err) {
      console.log(err);
    }

    window.scrollTo(0, 0);
  }, [location])

  return (
    <>
      <Navbar />
      <HoverFooter />
      <div className="main">
        <Routes>
          <Route path="/*"                  element={<Home />} />
          <Route path="/order-page/*"       element={<CheckoutPage />} />
          <Route path="/products/*"         element={<ProductPage />} />
          <Route path="/invoice/*"          element={<OrderPage />} />
          <Route path="/order"              element={<OrderPage />} />
          <Route path="/order/*"            element={<OrderPage />} />
          <Route path="/users/*"            element={<MemberDashboard />} />
          <Route path="/support/*"          element={<Support />} />
          <Route path="/terms-of-service"   element={<TermsOfService />} />
          <Route path="/refund-policy"      element={<RefundPolicy />} />            
          <Route path="/privacy"            element={<Privacy/> } />

          <Route path="/login"              element={<Login />} />
          <Route path="/register"           element={<Register />} />
          <Route path="/verify"             element={<VerifyEmailPage />} />
          <Route path="/forgot-password"    element={<ForgotPassword />} />
          <Route path="/reset-password"     element={<ResetPassword />} />
          <Route path="/special-accounts"   element={<SpecialAccounts />} />
          <Route path="/skyblock"           element={<SkyblockCoinsPage />} />
          <Route path="/404"                element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}