import { useState, useEffect }              from "react";
import { Link, useLocation, useNavigate }   from "react-router-dom";

import logo                                 from "../../images/logo.webp";

import { getCookie }                        from "../../util/cookies";
import { logout }                           from "../../util/logout";

import { useCurrency }                      from "../../context/CurrencyContext";

export default function Navbar() {
    const { currency, changeCurrency, currencyMenuClosed, closeCurrencyMenu } = useCurrency();
    const navigate = useNavigate();
    const location = useLocation();

    const mainRoute = location.pathname.split("/")[1] || "";
    const noCategoryRoutes = [
        "reset-password",
        "forgot-password",
        "users",
        "order-page",
        "register",
        "login",
        "verify",
        "support",
        "404",
        "order"
    ];
    const noTopNavRoutes = ["users", "order"];

    const [pendingScroll, setPendingScroll] = useState(null);

    const handleCurrencyChange = (cur) => {
        changeCurrency(cur);
        closeCurrencyMenu();
    };

    useEffect(() => {
        if (pendingScroll && location.pathname === "/") {
            setTimeout(() => {
                const element = document.getElementById(pendingScroll);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
                setPendingScroll(null);
            }, 500);
        }
    }, [location.pathname, pendingScroll]);

    const handleScroll = (sectionId) => {
        const element = document.getElementById(sectionId);

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        } else {
            setPendingScroll(sectionId);
            navigate("/");
        }
    };

    return (
        <div className="n-b-cont">
            {!noTopNavRoutes.includes(mainRoute) && (
                <div className="n-b-top">
                    <div className="n-b-top-contents">
                        <Link className="n-b-top-item" to="/special-accounts">Custom Accounts</Link>
                        <Link className="n-b-top-item" to="/support/popular-questions">Support</Link>
                        <div className="n-b-top-item" onClick={closeCurrencyMenu}>
                            <span>{currency.currency}</span>
                            {currencyMenuClosed ? <svg
                            version="1.1"
                            className="n-b-currency-arrow"
                            id="n-b-top-up"
                            viewBox="0 0 451.847 451.847"
                            fill="currentColor">
                            <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" />
                            </svg> :
                            <svg
                                version="1.1"
                                className="n-b-currency-arrow"
                                id="n-b-top-down"
                                viewBox="0 0 492.002 492.002"
                                fill="currentColor">
                                <path d="M484.136,328.473L264.988,109.329c-5.064-5.064-11.816-7.844-19.172-7.844c-7.208,0-13.964,2.78-19.02,7.844
                                    L7.852,328.265C2.788,333.333,0,340.089,0,347.297c0,7.208,2.784,13.968,7.852,19.032l16.124,16.124
                                    c5.064,5.064,11.824,7.86,19.032,7.86s13.964-2.796,19.032-7.86l183.852-183.852l184.056,184.064
                                    c5.064,5.06,11.82,7.852,19.032,7.852c7.208,0,13.96-2.792,19.028-7.852l16.128-16.132
                                    C494.624,356.041,494.624,338.965,484.136,328.473z" />
                            </svg>}
                        </div>
                        {!currencyMenuClosed && (
                            <div className="currency-selector">
                                <div className="select-item" onClick={() => handleCurrencyChange("USD")}>US Dollar (USD)</div>
                                <div className="select-item" onClick={() => handleCurrencyChange("CAD")}>Canadian (CAD)</div>
                                <div className="select-item" onClick={() => handleCurrencyChange("EUR")}>Euro (EUR)</div>
                                <div className="select-item" onClick={() => handleCurrencyChange("NZD")}>New Zealand (NZD)</div>
                                <div className="select-item" onClick={() => handleCurrencyChange("JPY")}>Japanese Yen (JPY)</div>
                                <div className="select-item" onClick={() => handleCurrencyChange("GBP")}>Pound Sterling (GBP)</div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className="n-b-flex">
                <nav className="mc-nav-bar">
                    <div className="mc-nav-left-side">
                        <Link to="/">
                            <div className="mc-nav-bar-name">
                                <img className="store-logo" src={logo} alt="greatalts logo" width={50} height={50} />
                                <div className="store-name">Great Alts</div>
                            </div>
                        </Link>
                        {!noCategoryRoutes.includes(mainRoute) && (
                            <nav className="pn-nav-bar">
                                <ol className="mc-nav-categories">
                                    <li className="pn-nav-bar-item" onClick={() => handleScroll("best-sellers")}>Best Sellers</li>
                                    <li className="pn-nav-bar-item" onClick={() => handleScroll("hypixel-section")}>Hypixel</li>
                                    <li className="pn-nav-bar-item" onClick={() => handleScroll("temporary-alts-section")}>Temporary Alts</li>
                                    <li className="pn-nav-bar-item" onClick={() => handleScroll("exclusive-section")}>Exclusive</li>
                                    <li className="pn-nav-bar-item" onClick={() => handleScroll("capes-section")}>Capes</li>
                                    <li className="pn-nav-bar-item" onClick={() => handleScroll("skyblock-section")}>Skyblock</li>
                                </ol>
                            </nav>
                        )}
                    </div>

                    <div className="mc-nav-bar-main">
                        {getCookie("UTOK") ? (
                            <div className="n-b-logged-in">
                                {mainRoute !== "users" ? <Link to="/users" className="n-b-action-item-cont">
                                    <div className="profile-text">Dashboard</div>
                                </Link> : <></>}
                                <div className="n-b-action-item-cont" onClick={logout}>
                                    <div className="profile-text">Sign Out</div>
                                </div>
                            </div>
                        ) : (
                            <Link to="/login">
                                <div className="n-b-action-item-cont">
                                    <div className="profile-text">Login</div>
                                </div>
                            </Link>
                        )}
                    </div>
                </nav>
            </div>
        </div>
    );
}