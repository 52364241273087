import { useGoogleLogin }               from "@react-oauth/google";
import { Link, useNavigate }            from "react-router-dom";
import { useEffect, useState }          from "react";

import { getCookie, setCookie }         from "../../util/cookies";
import { parseJwt }                     from "../../util/jwt";

import { loginUser }                    from "../../services/user";
import { getGoogleOAuth }               from "../../services/oauth";

import { useAlert }                     from "../../context/AlertContext";
import { ShopInfo }                     from "../../constants/AppConstants";

import RegistrationSuccess              from "../register/RegistrationSuccess";

export default function Login()
{
    const [email, setEmail]             = useState("");
    const [password, setPassword]       = useState("");

    const [showVerify, setShowVerify]   = useState(false);

    const { addAlert }                  = useAlert();
    
    const navigate                      = useNavigate();

    useEffect(() => {
        if (getCookie("UTOK"))
        {
            navigate("/users");
        }
    }, []);

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                // Fetch user details from backend
                const res = await getGoogleOAuth(response.access_token);

                if(res.status !== 201 || !res?.headers?.authorization)
                {
                    return addAlert("Failed to Login", "warning", 3000);
                }

                let token = res.headers.authorization;   
                setCookie("UTOK", token, 30);
    
                navigate(-1);
            } catch (err) {
                console.error("Google Login Failed:", err);
            }
        },
        onError: (error) => console.error("Google Login Failed:", error)
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            return addAlert("Enter Email and Password", "error", 4000);
        }

        let response = await loginUser({ email, password });

        if (response?.status === 401) return addAlert("Email or Password is incorrect.", "error", 4000);
        if (!response?.data || !response?.headers?.authorization) return addAlert("Unknown Error, Contact Support.", "error", 4000);

        let token = response.headers.authorization;
        const responseData = response.data;

        if (!responseData.verified) {
            setCookie("RTOK", token, 30);
            setShowVerify(true);
        } else {
            setCookie("UTOK", token, 30);

            const payload = parseJwt(token);

            if (payload.authorities.includes("UPLOADER")) {
                window.location.href = "https://dashboard.altsgreat.com";
            } else {
                navigate(-1);
            }
        }
    };

    return (
        <div>
            {!showVerify ? (
                <form id="member-login-cont" className="member-login-cont" onSubmit={handleSubmit}>
                    <div className="s-r-header-cont">
                        <h3 className="s-r-header">Sign In</h3>
                        <p className="s-r-h-msg">View your orders and rewards!</p>
                    </div>
                    <div className="s-r-input-cont">
                        <h4 className="s-r-input-header">Email</h4>
                        <input className="s-r-input" type="email" onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div className="s-r-input-cont">
                        <h4 className="s-r-input-header">Password</h4>
                        <input className="s-r-input" type="password" onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                
                    <Link to={email ? `/forgot-password?email=${email}` : `/forgot-password`} className="s-r-forgot-password">
                        Forgot Password?
                    </Link>
                    <input className="s-r-submit-btn" value="Sign In" type="submit" />
                    <div className="s-r-or-msg">or</div>
                    <div className="s-r-google-sign-in-cont" onClick={handleGoogleLogin}>
                        <div className="s-r-google-logo-cont">
                            <svg class="s-r-google-logo" viewBox="0 0 256 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                                <path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path>
                                <path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path>
                                <path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path>
                                <path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EA4335"></path>
                            </svg>
                        </div>
                        <div className="s-r-google-msg">Sign in with Google</div>
                    </div>
                    <div className="s-r-footer">
                        New to {ShopInfo.CompanyName}? <Link to="/register" className="s-r-switch">Register Now</Link> to track every purchase!
                    </div>
                </form>
            ) : (
                <RegistrationSuccess email={email} />
            )}
        </div>
    );
}