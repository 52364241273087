import './styles/global.css';

import React                        from 'react';
import ReactDOM                     from 'react-dom/client';
import { BrowserRouter as Router }  from 'react-router-dom';
import { GoogleOAuthProvider }      from '@react-oauth/google';
import { HelmetProvider }           from 'react-helmet-async';

import App                          from './App';


import { ProductProvider }          from './context/ProductsContext.js';
import { AlertProvider }            from './context/AlertContext.js';
import { CurrencyProvider }         from './context/CurrencyContext.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId='479214260763-odjnqh0rdj35kcr3c3sfs43fuk8nsmam.apps.googleusercontent.com'>
    <CurrencyProvider>
      <HelmetProvider>
        <ProductProvider>
          <AlertProvider>
            <Router>
              <App/>
            </Router>
          </AlertProvider>
        </ProductProvider>
      </HelmetProvider>
    </CurrencyProvider>
  </GoogleOAuthProvider>
);
