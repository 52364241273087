import { useEffect, useState }          from "react";
import { useLocation, useNavigate }     from "react-router-dom";

import { sendForgotPasswordLink }       from "../../services/user";

import { useAlert }                     from "../../context/AlertContext";

export default function ForgotPassword() {
    const [email, setEmail]     = useState("");

    const navigate              = useNavigate();
    const location              = useLocation();

    const { addAlert }          = useAlert();

    useEffect(() =>
    {
        const params = new URLSearchParams(location.search);
        const emailParam = params.get("email");

        if (emailParam)
        {
            setEmail(emailParam);
            navigate("/forgot-password", { replace: true });
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await sendForgotPasswordLink(email);

        if (response?.status === 200) {
            addAlert("If that email is registered, an email has been sent. Check spam/junk.", "success", 10000);
            setEmail("");
        } else {
            addAlert("Error, please try again. If issue persists, contact support.", "error", 5000);
        }
    };

    return (
        <div>
            <form className="member-login-cont" onSubmit={handleSubmit}>
                <div className="s-r-header-cont">
                    <h3 className="s-r-header">Forgot Password</h3>
                    <p className="s-r-h-msg">Enter the email associated with your account.</p>
                </div>
                <div className="s-r-input-cont">
                    <h4 className="s-r-input-header">Email</h4>
                    <input 
                        className="s-r-input" 
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <input className="s-r-submit-btn" value="Continue" type="submit" />
            </form>
        </div>
    );
}