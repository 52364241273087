import { Link } from 'react-router-dom';
import { ShopInfo } from '../../../constants/AppConstants';

export default function AboutUsQuestions() {
    return (
        <>
            <div className="support-questions-cont">
                <div className="support-questions-header">About Us</div>
                <div className="support-main-questions-cont">
                    <Link to="/support/aboutus/who-are-we" className="support-main-questions-item">
                        Who are we?
                    </Link>
                    <Link to="/support/aboutus/is-alts-great-legit-and-safe-to-buy-from" className="support-main-questions-item">
                        What or who is {ShopInfo.CompanyName}?
                    </Link>
                    <Link to="/support/aboutus/is-alts-great-legit-and-safe-to-buy-from" className="support-main-questions-item">
                        Is {ShopInfo.CompanyName} legit and safe to buy from?
                    </Link>
                    <Link to="/support/aboutus/how-does-this-work" className="support-main-questions-item">
                        How does this work?
                    </Link>
                </div>
            </div>
        </>
    );
}