export const AppConstants = 
{
    DOMAIN: "altsgreat.com",

    BASE_URL: "http://localhost:8080",
    WEB_URL: "http://localhost:3000",
    
    DISCORD_REGULAR_INVITE: "https://discord.com/invite/wrsrKw3dTv",

    BASE_URL: "https://altsgreat-72x9.onrender.com",
    WEB_URL: "https://altsgreat.com"
}

export const ShopInfo = 
{
    CompanyName: "AltsGreat",
    RefundPolicy: `${AppConstants.WEB_URL}/help/return-policy`
}

export const RouteConstants =
{
    SECURER: "/securer",
    LOGIN_API_URL: "/user",
    REGISTER_URL: "/register",
    AUTHORITIES_URL: "/authorities",
    PRODUCTS_URL: "/products",
    INVOICES_URL: "/invoices",
    OAUTH_URL: "/oauth",
    USERS_URL: "/users",
    REGISTRATION_LINK: "/register",
    SERIAL_URL: "/serials",
    ACCOUNT_URL: "/accounts",
    REFERENCE_URL: "/references",
    CURRENCY_URL: "/api/currency/rates",
    DISCORD_URL: "/api/discord",
    RANDOM_URL: "/random"
}